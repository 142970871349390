import Locale from '../util/Locale';

const StoreSwitcherContainer_mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        hreflang: state.UrlRewritesReducer.urlRewrite?.hreflang ?? [],
    };
};

const StoreSwitcherContainer_handleStoreSelect = (args, callback, instance) => {
    const storeCode = args[0];
    const { hreflang } = instance.props;
    const { storeList } = instance.state;

    const store = storeList.find(({ value }) => value === storeCode);

    try {
        Locale.setHreflang(hreflang);
        Locale.replaceLocale(storeCode);
    } catch (error) {
        window.location = store.storeLinkUrl;
    }
};

export default {
    'Component/StoreSwitcher/Container/mapStateToProps': {
        function: StoreSwitcherContainer_mapStateToProps,
    },
    'Component/StoreSwitcher/Container': {
        'member-function': {
            handleStoreSelect: StoreSwitcherContainer_handleStoreSelect,
        },
    },
};
