import Check from '@rokita/permissions';
import { lazy, Suspense } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader/Loader.component';
import NoMatch from 'Route/NoMatch';
import { MyAccount as SourceMyAccount } from 'SourceRoute/MyAccount/MyAccount.component';
import {
    ARRANGEMENTS,
    FINANCIAL_DOCUMENT,
    FORMS,
    ORDER_HISTORY,
    PARTNERS,
    PRICE_LIST,
    USER_MANAGE,
} from 'Type/Account.type';

import 'Route/MyAccount/MyAccount.style';

export const MyAccountForm = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-address" */
        'Component/MyAccountForm'
    )
);
export const MyAccountPartners = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-partners" */
        'Component/MyAccountPartners'
    )
);
export const MyAccountDownloadable = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-downloadable" */
        'Component/MyAccountDownloadable'
    )
);
export const MyAccountOrderHistory = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-order-history" */
        'Component/MyAccountOrderHistory'
    )
);
export const MyAccountArrangements = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-arrangements " */
        'Component/MyAccountArrangements'
    )
);
export const MyAccountPriceList = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-price-list" */
        'Component/MyAccountPriceList'
    )
);
export const MyAccountFinancialDocuments = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-financial-documents" */
        'Component/MyAccountFinancialDocuments'
    )
);

export const MyAccountUserManage = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-users" */
        'Component/MyAccountUserManage'
    )
);

/** @namespace RokitaBasic/Route/MyAccount/Component */
export class MyAccount extends SourceMyAccount {
    renderMap = {
        [PARTNERS]: MyAccountPartners,
        [ORDER_HISTORY]: MyAccountOrderHistory,
        [FINANCIAL_DOCUMENT]: MyAccountFinancialDocuments,
        [ARRANGEMENTS]: MyAccountArrangements,
        [FORMS]: MyAccountForm,
        [PRICE_LIST]: MyAccountPriceList,
        [USER_MANAGE]: MyAccountUserManage,
    };

    renderContent() {
        const { activeTab, tabMap, isEditingActive, match, changeTabName, tabName, setTabSubheading, isTabEnabled } =
            this.props;

        if (!isTabEnabled(activeTab)) {
            return <NoMatch />;
        }

        const TabContent = this.getTabContent();
        const { title, hideName = false, permissions = [] } = tabMap[activeTab];

        return (
            <Check permissions={permissions} fallback={<NoMatch />}>
                <ContentWrapper label={__('My Account page')} wrapperMix={{ block: 'MyAccount', elem: 'Wrapper' }}>
                    <div block="MyAccount" elem="TabContent">
                        {!hideName && (
                            <h3 block="MyAccount" elem="Heading">
                                {__(title || tabName)}
                                {this.renderSubHeading()}
                            </h3>
                        )}
                        <Suspense fallback={<Loader />}>
                            <TabContent
                                isEditingActive={isEditingActive}
                                match={match}
                                changeTabName={changeTabName}
                                tabMap={tabMap}
                                setTabSubheading={setTabSubheading}
                            />
                        </Suspense>
                    </div>
                </ContentWrapper>
            </Check>
        );
    }
}

export default MyAccount;
