/** @namespace Rokita/Storefront/Util/Url */
export class Url {
    origin = window.location.origin;

    replaceOrigin(value) {
        return new URL(value, this.origin).toString();
    }

    redirect(value) {
        window.location = new URL(value + window.location.search, this.origin).toString();
    }
}

export default new Url();
