import { ONE_MONTH_IN_SECONDS, QueryDispatcher as SourceQueryDispatcher } from 'SourceUtil/Request/QueryDispatcher';
import { Field, prepareQuery } from 'Util/Query';
import { executeGet, listenForBroadCast } from 'Util/Request/Request';

export * from 'SourceUtil/Request/QueryDispatcher';

/** @namespace RokitaBasic/Util/Request/QueryDispatcher */
export class QueryDispatcher extends SourceQueryDispatcher {
    __construct(name, cacheTTL = ONE_MONTH_IN_SECONDS) {
        super.__construct(name, cacheTTL);
    }

    async handleData(dispatch, options) {
        const { name, cacheTTL } = this;

        const rawQueries = this.prepareRequest(options, dispatch);

        if (!rawQueries) {
            return;
        }

        const queries = rawQueries instanceof Field ? [rawQueries] : rawQueries;

        this.controller = new AbortController();

        try {
            this.promise = await executeGet(prepareQuery(queries), name, cacheTTL, this.controller.signal);
            this.onSuccess(this.promise, dispatch, options);
        } catch (err) {
            this.onError(err, dispatch, options);
        }
        const broadcast = await listenForBroadCast(name);

        this.onUpdate(broadcast, dispatch, options);
    }
}

export default QueryDispatcher;
