import { getDateValue, groupDateFieldsData, transformDateFieldsData } from '@scandipwa/scandipwa/src/util/Form/Extract';

import { FIELD_TYPE_ATTR } from 'Component/DateSelect/DateSelect.config';
import FIELD_TYPE from 'Component/Field/Field.config';
import { FIELD_DATE_TYPE } from 'Component/FieldDate/FieldDate.config';
import { FIELD_SELECT_SEARCH_ROLE } from 'Component/FieldSelect/FieldSelect.config';

export * from 'SourceUtil/Form/Extract';

/** @namespace RokitaBasic/Util/Form/Extract/getFieldsData */
export const getFieldsData = (DOM, excludeEmpty = false, ignoreTypes = [], asObject = false) => {
    const fields = DOM.querySelectorAll('input, textarea, select');
    const output = [];

    const dateFieldsGrouped = groupDateFieldsData(fields);

    output.push(...transformDateFieldsData(dateFieldsGrouped));

    fields.forEach((field) => {
        if (Object.values(FIELD_DATE_TYPE).includes(field.getAttribute(FIELD_TYPE_ATTR))) {
            return;
        }

        const { tagName } = field;
        const tag = tagName.toLowerCase();
        const type =
            tag === FIELD_TYPE.textarea || tag === FIELD_TYPE.select || tag === FIELD_TYPE.button ? tag : field.type;

        if (ignoreTypes.some((ignoreType) => ignoreType === type || field.role === FIELD_SELECT_SEARCH_ROLE)) {
            return;
        }

        // eslint-disable-next-line no-nested-ternary
        const value =
            type === FIELD_TYPE.checkbox || type === FIELD_TYPE.radio
                ? // eslint-disable-next-line no-nested-ternary
                  field.checked
                    ? field.value === 'on'
                        ? true
                        : field.value
                    : false
                : type === FIELD_TYPE.file
                ? field.fileData
                : field.value;

        const dateValue = field.getAttribute('data-date');
        const formattedValue = dateValue ? getDateValue(dateValue) : value;

        if (!!field.name && (!excludeEmpty || value)) {
            output.push({
                name: field.name,
                type,
                value: formattedValue,
                field,
            });
        }
    });

    if (asObject) {
        const objectOutput = {};

        output.forEach((field) => {
            const { name } = field;

            objectOutput[name] = field;
        });

        return objectOutput;
    }

    return output;
};

/** @namespace RokitaBasic/Util/Form/Extract/resetField */
export const resetField = (field) => {
    if (!field) return;
    if (typeof field.reset === 'function') {
        field.reset();
    } else if (typeof field.dispatchEvent === 'function') {
        field.dispatchEvent(new Event('resetField'));
    }
};

export default getFieldsData;
