export const CLEAR_PARTNERS = 'CLEAR_PARTNERS';
export const GET_PARTNERS = 'GET_PARTNERS';

/** @namespace RokitaBasic/Store/Partners/Actions/clearPartners */
export const clearPartners = (partners = [], technicalSupport = {}) => ({
    type: CLEAR_PARTNERS,
    partners,
    technicalSupport,
});

/** @namespace RokitaBasic/Store/Partners/Actions/getPartners */
export const getPartners = (partners, technicalSupport = {}) => ({
    type: GET_PARTNERS,
    partners,
    technicalSupport,
});
