/** @namespace RokitaBasic/Util/SessionDatabase */
export class SessionDatabase {
    getItem(location) {
        try {
            const entryObject = JSON.parse(sessionStorage.getItem(location));
            const { data, expiration, createdAt } = entryObject;
            const MILLISECONDS_TO_SECONDS = 1000;

            if (expiration && Date.now() - createdAt > expiration * MILLISECONDS_TO_SECONDS) {
                sessionStorage.removeItem(location);

                return null;
            }

            return data;
        } catch {
            return null;
        }
    }

    setItem(data, location, expiration) {
        sessionStorage.setItem(
            location,
            JSON.stringify({
                data,
                expiration,
                createdAt: Date.now(),
            })
        );
    }

    deleteItem(location) {
        sessionStorage.removeItem(location);
    }
}

export default new SessionDatabase();
