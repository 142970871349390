import { getStaticReducers as sourceGetStaticReducers } from 'SourceStore';
import AttributesReducer from 'Store/Attributes/Attributes.reducer';
import FinancialDocumentReducer from 'Store/FinancialDocument/FinancialDocument.reducer';
import PartnersReducer from 'Store/Partners/Partners.reducer';
import SalesDepartmentReducer from 'Store/SalesDepartment/SalesDepartment.reducer';
import SidebarReducer from 'Store/Sidebar/Sidebar.reducer';
import TradeAgreementCartReducer from 'Store/TradeAgreementCart/TradeAgreementCart.reducer';

/** @namespace RokitaBasic/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ...sourceGetStaticReducers(),
    SidebarReducer,
    FinancialDocumentReducer,
    SalesDepartmentReducer,
    PartnersReducer,
    TradeAgreementCartReducer,
    AttributesReducer,
});

export default function injectStaticReducers(store) {
    // eslint-disable-next-line no-param-reassign
    store.asyncReducers = [];

    // Inject all the static reducers into the store
    Object.entries(getStaticReducers()).forEach(([name, reducer]) => {
        // eslint-disable-next-line no-param-reassign
        store.asyncReducers[name] = reducer;
        store.injectReducer(name, reducer);
    });

    return store;
}
