import Locale from '../util/Locale';
import Url from '../util/Url';

const StoreSwitcherContainer_formatStoreList = (args, callback) => {
    const storeList = callback(...args);

    return storeList.map(({ storeUrl, storeLinkUrl, ...rest }) => {
        const { pathname: storePathname, storeOrigin } = new URL(storeUrl);
        const { pathname: storeLinkPathname, storeLinkOrigin } = new URL(storeLinkUrl);

        if (storeOrigin === Url.origin && storeLinkOrigin === Url.origin) {
            return {
                storeUrl,
                storeLinkUrl,
                ...rest,
            };
        }

        return {
            storeUrl: Url.replaceOrigin(storePathname),
            storeLinkUrl: Url.replaceOrigin(storeLinkPathname),
            ...rest,
        };
    });
};

const StoreSwitcherContainer_handleStoreSelect = (args, callback, instance) => {
    const storeCode = args[0];
    const { hreflang } = instance.props;
    const { storeList } = instance.state;

    const store = storeList.find(({ value }) => value === storeCode);

    try {
        Locale.setHreflang(hreflang);
        Locale.replaceLocale(storeCode);
    } catch (error) {
        window.location = store.storeLinkUrl;
    }
};

export default {
    'Component/StoreSwitcher/Container': {
        'member-function': {
            _formatStoreList: StoreSwitcherContainer_formatStoreList,
            handleStoreSelect: StoreSwitcherContainer_handleStoreSelect,
        },
    },
};
