import { CLEAR_PARTNERS, GET_PARTNERS } from './Partners.actions';

/** @namespace RokitaBasic/Store/Partners/Reducer/getInitialState */
export const getInitialState = () => ({
    partners: {},
    technicalSupport: {},
});

/** @namespace RokitaBasic/Store/Partners/Reducer/PartnersReducer */
export const PartnersReducer = (state = getInitialState(), action) => {
    const { type, partners, technicalSupport } = action;

    switch (type) {
        case GET_PARTNERS:
            return {
                partners,
                technicalSupport,
            };

        case CLEAR_PARTNERS:
            return {
                partners,
                technicalSupport,
            };

        default:
            return state;
    }
};

export default PartnersReducer;
