/** @namespace RokitaBasic/Util/Object/flattenObject */
export const flattenObject = (obj, parent, res = {}) => {
    for (const key of Object.keys(obj)) {
        const propName = parent ? `${parent}.${key}` : key;
        if (typeof obj[key] === 'object') {
            flattenObject(obj[key], propName, res);
        } else {
            res[propName] = obj[key];
        }
    }

    return res;
};
