import FileSaver from 'file-saver';
import mime from 'mime-types';

import { showNotification } from 'Store/Notification/Notification.action';
import { getErrorMessage } from 'Util/Request/Error';
import { joinTextWithSeparator } from 'Util/Text';

/** @namespace RokitaBasic/Util/File/saveAs */
export const saveAs = async ({ filename, filetype, content } = {}, dispatch) => {
    try {
        const request = await fetch(`data:${mime.lookup(filetype)};base64,${content}`);

        if (!request.ok) {
            return;
        }

        FileSaver.saveAs(await request.blob(), joinTextWithSeparator([filename, filetype], '.'));
    } catch (error) {
        dispatch(showNotification('error', getErrorMessage(error), error));
    }
};

/** @namespace RokitaBasic/Util/File/getParsedFile */
export const getParsedFile = (file) =>
    new Promise((resolve) => {
        if (!file) {
            return resolve(null);
        }

        const reader = new FileReader();

        reader.onload = () => {
            const content = reader.result.split(',')[1];
            const filename = file.name.replace(/\.[^/.]+$/, '');
            const filetype = file.name.split('.').pop();
            const filesize = file.size;

            return resolve({
                content,
                filename,
                filetype,
                filesize,
            });
        };

        reader.onerror = () => {
            return resolve(null);
        };

        reader.readAsDataURL(file);
    });
