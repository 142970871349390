import React, { PureComponent } from 'react';

/** @namespace RokitaBasic/Component/CalendarIcon/Component */
export class CalendarIcon extends PureComponent {
    render() {
        return (
            <svg
                block="CalendarIcon"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
            >
                <path
                    d="M10.6667 1.66732H10V0.333984H8.66667V1.66732H3.33333V0.333984H2V1.66732H1.33333C0.593333 1.66732 0.00666666 2.26732 0.00666666 3.00065L0 12.334C0 13.0673 0.593333 13.6673 1.33333 13.6673H10.6667C11.4 13.6673 12 13.0673 12 12.334V3.00065C12 2.26732 11.4 1.66732 10.6667 1.66732ZM10.6667 12.334H1.33333V5.66732H10.6667V12.334ZM10.6667 4.33398H1.33333V3.00065H10.6667V4.33398ZM4 8.33398H2.66667V7.00065H4V8.33398ZM6.66667 8.33398H5.33333V7.00065H6.66667V8.33398ZM9.33333 8.33398H8V7.00065H9.33333V8.33398ZM4 11.0007H2.66667V9.66732H4V11.0007ZM6.66667 11.0007H5.33333V9.66732H6.66667V11.0007ZM9.33333 11.0007H8V9.66732H9.33333V11.0007Z"
                    fill="currentColor"
                />
            </svg>
        );
    }
}

export default CalendarIcon;
