export * from 'SourceComponent/Notification/Notification.config';

export const NOTIFICATION_LIFETIME = 5000;
export const ERROR_NOTIFICATION_LIFETIME = 5000;

export const notificationType = {
    success: 'success',
    error: 'error',
    info: 'info',
    warning: 'warning',
};
