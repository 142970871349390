import { UPDATE_ATTRIBUTES } from './Attributes.action';

/** @namespace RokitaBasic/Store/Attributes/Reducer/getInitialState */
export const getInitialState = () => ({
    attributes: [],
    descriptions: {},
});

/** @namespace RokitaBasic/Store/Attributes/Reducer/AttributesReducer */
export const AttributesReducer = (state = getInitialState(), action) => {
    const { type, attributes, descriptions } = action;

    switch (type) {
        case UPDATE_ATTRIBUTES:
            return {
                ...state,
                attributes,
                descriptions,
            };
        default:
            return state;
    }
};

export default AttributesReducer;
