import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Html from 'Component/Html';
import Loader from 'Component/Loader';

import './Consents.style';

/** @namespace RokitaBasic/Component/Consents/Component */
export class Consents extends PureComponent {
    static propTypes = {
        data: PropTypes.arrayOf(
            PropTypes.shape({
                consent_id: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                is_required: PropTypes.bool.isRequired,
                is_checkbox: PropTypes.bool.isRequired,
            })
        ),
    };

    static defaultProps = {
        data: [],
    };

    renderConsent(data) {
        const { consent_id, is_checkbox, is_required: isRequired, description } = data;

        if (!is_checkbox) {
            return <Html key={consent_id} content={description} />;
        }

        return (
            <Field
                key={consent_id}
                attr={{ name: consent_id }}
                type={FIELD_TYPE.checkbox}
                addRequiredTag={isRequired}
                validateOn={['onChange']}
                validationRule={{
                    isRequired,
                }}
                label={<Html content={description} />}
            />
        );
    }

    render() {
        const { data } = this.props;

        if (data === null) {
            return <Loader isLoading />;
        }

        if (!data || !data.length) {
            return null;
        }

        return <div block="Consents">{data.map((item) => this.renderConsent(item))}</div>;
    }
}

export default Consents;
