export * from 'SourceUtil/Request/Error';

export const DEFAULT_ERROR_MESSAGE = __('Something went wrong!');

/* @namespace RokitaBasic/Util/Request/Error/getErrorMessage */
export const getErrorMessage = (error, defaultMessage = DEFAULT_ERROR_MESSAGE) => {
    const { message } = error?.length ? error[0] || {} : error || {};

    if (message) return __(message);

    return defaultMessage;
};

/** @namespace RokitaBasic/Util/Request/Error/getErrorCategory */
export const getErrorCategory = (error) => {
    const { extensions } = error?.length ? error[0] : error || {};
    const { category } = extensions || {};

    return category;
};
