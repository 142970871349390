import ContentWrapper from 'Component/ContentWrapper';
import Link from 'Component/Link';
import { NoMatch as SourceNoMatch } from 'SourceRoute/NoMatch/NoMatch.component';

import './NoMatch.style';

/** @namespace RokitaBasic/Route/NoMatch/Component */
export class NoMatch extends SourceNoMatch {
    render() {
        return (
            <main block="NoMatch" aria-label={__('Page not found')}>
                <ContentWrapper
                    mix={{ block: 'NoMatch' }}
                    wrapperMix={{ block: 'NoMatch', elem: 'Wrapper' }}
                    label={__('Page Not Found Content')}
                >
                    <p block="NoMatch" elem="Subtitle">
                        {__('No such page was found or you do not have permission to access it.')}
                    </p>
                    <p>{__('Please press a button below to go back to homepage.')}</p>
                    <Link to="/" block="NoMatch" elem="Button" mix={{ block: 'Button' }}>
                        {__('Back to homepage')}
                    </Link>
                </ContentWrapper>
            </main>
        );
    }
}

export default NoMatch;
