import PropTypes from 'prop-types';

import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';

/**
 * Field
 * @class FieldContainer
 * @namespace RokitaBasic/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        ...SourceFieldContainer.propTypes,
        floatingLabel: PropTypes.bool,
        checkIncrements: PropTypes.bool,
        customInput: PropTypes.node,
    };

    static defaultProps = {
        ...SourceFieldContainer.defaultProps,
        floatingLabel: true,
        checkIncrements: true,
        customInput: null,
    };

    containerFunctions = {
        ...this.containerFunctions,
        toggleVisiblePassword: this.toggleVisiblePassword.bind(this),
    };

    state = {
        validationResponse: null,
        showLengthError: false,
        visiblePassword: false,
    };

    // Adds validation event listener to field
    setRef(elem) {
        const { elemRef } = this.props;
        const { validationRule, type, attr } = this.props;

        if (elem && this.fieldRef !== elem) {
            this.fieldRef = elem;

            if (elemRef) {
                if (typeof elemRef === 'function') {
                    elemRef(elem, { validationRule, type, attr });
                } else {
                    elemRef.current = elem;
                }
            }

            elem.addEventListener('resetField', this.resetField.bind(this));

            if (!validationRule || Object.keys(validationRule).length === 0) {
                return;
            }
            elem.addEventListener('validate', this.validate.bind(this));
        }
    }

    toggleVisiblePassword() {
        this.setState((prev) => ({
            ...prev,
            visiblePassword: !prev.visiblePassword,
        }));
    }

    containerProps() {
        const { floatingLabel, checkIncrements, customInput } = this.props;
        const { visiblePassword } = this.state;

        return {
            ...super.containerProps(),
            visiblePassword,
            floatingLabel,
            checkIncrements,
            customInput,
        };
    }
}

export default FieldContainer;
