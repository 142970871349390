export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

/* @namespace RokitaBasic/Store/Sidebar/Action/toggleSidebarAction */
export const toggleSidebarAction = (options) => ({
    type: TOGGLE_SIDEBAR,
    options,
});

/* @namespace RokitaBasic/Store/Sidebar/Action/openSidebarAction */
export const openSidebarAction = (options) => ({
    type: OPEN_SIDEBAR,
    options,
});

/* @namespace RokitaBasic/Store/Sidebar/Action/closeSidebarAction */
export const closeSidebarAction = (options) => ({
    type: CLOSE_SIDEBAR,
    options,
});
