import qs from 'qs';

import { trimEndSlash } from 'SourceUtil/Url/Url';
import getStore from 'Util/Store';

export * from 'SourceUtil/Url/Url';

export const QUERY_PAGE_NAME = 'page';
export const QUERY_PER_PAGE_NAME = 'perPage';
export const QUERY_FILTER_NAME = 'filter';
export const QUERY_SORT_NAME = 'sort';

/** @namespace RokitaBasic/Util/Url/appendWithStoreCode */
export const appendWithStoreCode = (pathname) => {
    const { ConfigReducer: { base_link_url = window.location.href } = {} } = getStore().getState() || {};
    const { pathname: storePrefix } = new URL(base_link_url);

    if (!pathname) {
        return trimEndSlash(storePrefix);
    }

    // match URLs which have the store code in pathname
    if (new RegExp(`^/(${window.storeList.join('|')})/`, 'i').test(pathname)) {
        return pathname;
    }

    return [
        `/${storePrefix?.split('/').filter(Boolean)[0]}`,
        pathname.startsWith('/') ? pathname.replace(/^(\/)/, '') : pathname,
    ]
        .filter(Boolean)
        .join('/');
};

/** @namespace RokitaBasic/Util/Url/getPathnameParams */
export const getPathnameParams = (match) => {
    if (!match || !match.params || !match.params.params) return [];

    const params = match.params.params ?? [];
    return params.split('/');
};

/** @namespace RokitaBasic/Util/Url/clearParametersUrl */
export const clearParametersUrl = (history) => {
    history.push({
        search: undefined,
    });
};

/** @namespace RokitaBasic/Util/Url/getParametersFromUrl */
export const getParametersFromUrl = (location) => {
    const { search: currentSearch = '' } = location ?? {};

    return (
        qs.parse(currentSearch ?? '', {
            ignoreQueryPrefix: true,
            comma: true,
        }) ?? {}
    );
};

/** @namespace RokitaBasic/Util/Url/generateParametersToUrl */
export const generateParametersToUrl = (parameters) => {
    return qs.stringify(parameters ?? {}, { encodeValuesOnly: true, arrayFormat: 'comma' });
};

/** @namespace RokitaBasic/Util/Url/setParametersToUrl */
export const setParametersToUrl = (parameters, location, history) => {
    const { search: currentSearch = '' } = location ?? {};

    const search = generateParametersToUrl({
        ...Object.entries(getParametersFromUrl(currentSearch))
            .filter(([key]) => key !== QUERY_FILTER_NAME && key !== QUERY_SORT_NAME)
            .reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {}),
        ...(parameters ?? {}),
    });

    if (history) {
        history.push({
            search,
        });
    }

    return search;
};
