import { CLEAR_SALES_DEPARTMENT, GET_SALES_DEPARTMENT } from './SalesDepartment.actions';

/** @namespace RokitaBasic/Store/SalesDepartment/Reducer/getInitialState */
export const getInitialState = () => ({
    salesDepartmentAll: [],
    salesDepartmentOnlyActive: [],
});

/** @namespace RokitaBasic/Store/SalesDepartment/Reducer/SalesDepartmentReducer */
export const SalesDepartmentReducer = (state = getInitialState(), action) => {
    const { type, salesDepartmentAll, salesDepartmentOnlyActive } = action;

    switch (type) {
        case GET_SALES_DEPARTMENT:
            return {
                salesDepartmentAll,
                salesDepartmentOnlyActive,
            };

        case CLEAR_SALES_DEPARTMENT:
            return {
                salesDepartmentAll,
                salesDepartmentOnlyActive,
            };

        default:
            return state;
    }
};

export default SalesDepartmentReducer;
