import { Field } from 'Util/Query';

/** @namespace RokitaBasic/Query/Partnerts/Query */
export class PartnersQuery {
    getContractorStructure(id) {
        return new Field('getContractorStructure')
            .setAlias('structures')
            .addArgument('salesDepartmentId', 'Int!', id)
            .addFieldList(this._getContractorStructureFields());
    }

    getUserPartners(role, { salesDepartment = null, withAlias = false }) {
        if (withAlias) {
            const field = new Field('getUserPartners')
                .setAlias(role.toLowerCase())
                .addArgument('role', 'String!', role)
                .addFieldList(this._getPartnersFields());

            if (salesDepartment) {
                field.addArgument('salesDepartmentId', 'Int', salesDepartment);
            }

            return field;
        }

        const field = new Field('getUserPartners')
            .addArgument('role', 'String!', role)
            .addFieldList(this._getPartnersFields());

        if (salesDepartment) {
            field.addArgument('salesDepartmentId', 'Int', salesDepartment);
        }

        return field;
    }

    getTechnicalSupport() {
        return new Field('storeConfig').setAlias('technicalSupport').addFieldList(this._getTechnicalSupportFields());
    }

    _getTechnicalSupportFields() {
        return [
            new Field('tech_support_person_name').setAlias('name'),
            new Field('tech_support_person_phone').setAlias('phone'),
            new Field('tech_support_person_email').setAlias('email'),
        ];
    }

    _getContractorStructureFields() {
        return ['name', 'code', new Field('items').addFieldList(this._getPartnersFields())];
    }

    _getPartnersFields() {
        return [
            'city',
            'contractor_id',
            'country',
            'customer_group_id',
            'distribution_channel',
            'email',
            'external_id',
            'field',
            'firstname',
            'house_number',
            'house_number_supplement',
            'id',
            'lastname',
            'name',
            'partners_amount',
            'payment_terms',
            'phone',
            'postcode',
            'street',
            'street2',
            'street3',
            'street4',
            'street5',
        ];
    }
}

export default new PartnersQuery();
