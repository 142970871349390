export * from 'SourceRoute/MyAccount/MyAccount.config';

export const ACCOUNT_FORGOT_PASSWORD_URL = '/account/forgotpassword';
export const ACCOUNT_CHANGE_PASSWORD_URL = '/account/createPassword';
export const ACCOUNT_LOGIN_URL = '/account/login';
export const ACCOUNT_VERIFICATION_URL = '/account/verification';
export const ACCOUNT_LOGIN_AS_USER_URL = '/loginasuser';

export const ACCOUNT_URL = '/my-account';

export const ACCOUNT_PARTNERS_URL = '/my-account/partners';
export const ACCOUNT_ORDER_HISTORY_URL = '/my-account/order-history';
export const ACCOUNT_FINANCIAL_DOCUMENT_URL = '/my-account/financial-document';
export const ACCOUNT_ARRANGEMENTS_URL = '/my-account/arrangements';
export const ACCOUNT_PRICE_LIST_URL = '/my-account/price-list';
export const ACCOUNT_FORMS_URL = '/my-account/form';
export const ACCOUNT_USER_MANAGE_URL = '/my-account/user-manage';

export const PERMISSIONS = {
    FINANCIAL_DOCUMENT: ['show-financial-doc'],
    SEND_FORM: ['send-form'],
    PARTNERS: ['show-contractor-structure'],
    LISTING_USERS: ['listing-users'],
    USER_MANAGE: ['listing-users', 'create-user', 'edit-user'],
    USER_PERMISSIONS_MANAGE: ['user-permission-manage'],
    EDIT_USER: ['edit-user'],
    CREATE_USER: ['create-user'],
    USER_ROLE_EDIT: ['user-role-edit'],
    USER_ROLE_CREATE: ['user-role-create'],
    CREATE_EDIT_USER: ['create-user', 'edit-user'],
    TRADE_AGREEMENT: ['show-trade-agreement'],
    TRADE_AGREEMENT_PRICE_LIST: ['show-products-prices-trade-agreement'],
    PRODUCT_LIST: ['show-products-price-list'],
    PRODUCT_PRICE_LIST: ['show-products-prices-price-list'],
    ORDER_PLACEMENT_PRICE_LIST: ['order-placement-price-list'],
    ORDER_PLACEMENT_TRADE_AGREEMENT: ['order-placement-trade-agreement'],
    ORDER_HISTORY: ['show-order-history'],
};
