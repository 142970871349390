import CategoryQuery from 'Query/Category.query';
import { UPDATE_CONFIG } from 'Store/Config/Config.action';
import BrowserDatabase from 'Util/BrowserDatabase';

const { defaultCategory } = BrowserDatabase.getItem('config') || {
    defaultCategory: {},
};

const ConfigReducer_getInitialState = (args, callback) => ({
    ...callback(...args),
    defaultCategory,
});

const ConfigReducer_reducer = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { type, config: { defaultCategory = {} } = {} } = action;

    if (type !== UPDATE_CONFIG) {
        return originalUpdatedState;
    }

    return {
        ...originalUpdatedState,
        defaultCategory,
    };
};

export const ConfigDispatcher_prepareRequest = (args, callback) => [
    ...callback(...args),
    CategoryQuery.getCategoryDefault(),
];

export const ConfigQuery__getStoreConfigFields = (args, callback) => [
    ...callback(...args),
    'two_factor_verification_enabled',
    'product_attachment_enabled',
    'order_attachment_allowed_extensions',
    'order_attachment_max_file_size',
    'webapi_jwtauth_customer_expiration',
];

export default {
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: ConfigDispatcher_prepareRequest,
        },
    },
    'Store/Config/Reducer/getInitialState': {
        function: ConfigReducer_getInitialState,
    },
    'Store/Config/Reducer/ConfigReducer': {
        function: ConfigReducer_reducer,
    },
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields: ConfigQuery__getStoreConfigFields,
        },
    },
};
