export const UPDATE_ATTRIBUTES = 'UPDATE_ATTRIBUTES';

/* @namespace RokitaBasic/Store/Attributes/Action/updateAttributesAction */
export const updateAttributesAction = (attributes, descriptions = {}) => ({
    type: UPDATE_ATTRIBUTES,
    attributes,
    descriptions,
});

/* @namespace RokitaBasic/Store/Attributes/Action/clearAttributesAction */
export const clearAttributesAction = (attributes = [], descriptions = {}) => ({
    type: UPDATE_ATTRIBUTES,
    attributes,
    descriptions,
});
