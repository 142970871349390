import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Consents from 'Component/Consents';
import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountSignInContainer as SourceMyAccountSignInContainer,
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.container';
import transformToNameValuePair from 'Util/Form/Transform';
import { getErrorMessage } from 'Util/Request/Error';

/** @namespace RokitaBasic/Component/MyAccountSignIn/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    storeCode: state.ConfigReducer.code,
});

/** @namespace RokitaBasic/Component/MyAccountSignIn/Container */
export class MyAccountSignInContainer extends SourceMyAccountSignInContainer {
    static propTypes = {
        ...SourceMyAccountSignInContainer.propTypes,
        verification: PropTypes.func.isRequired,
        isVerification: PropTypes.bool.isRequired,
        isConsents: PropTypes.bool.isRequired,
        consents: PropTypes.arrayOf(PropTypes.object),
    };

    static defaultProps = {
        consents: [],
    };

    async onSignInSuccess(form, fields) {
        const {
            signIn,
            onSignIn,
            setLoadingState,
            handleConsentsToState,
            handleConsentsAccept,
            isConsentsLoaded,
            consents,
            showNotification,
        } = this.props;

        try {
            const fieldPairs = transformToNameValuePair(fields);

            setLoadingState(true);

            await signIn(fieldPairs);

            if (isConsentsLoaded) {
                const consentsToAccept = Consents.getAcceptedConsents({ fields, consents });

                if (consentsToAccept.length) {
                    await handleConsentsAccept(consentsToAccept);
                }
            } else {
                await handleConsentsToState(fieldPairs, false);
            }

            onSignIn();
            setLoadingState(false);
        } catch (error) {
            showNotification('error', getErrorMessage(error), error);
            setLoadingState(false);
        }
    }

    containerProps = () => {
        const {
            state,
            onFormError,
            handleForgotPassword,
            handleCreateAccount,
            isCheckout,
            setLoadingState,
            emailValue,
            handleEmailInput,
            isConsents,
            consents,
            storeCode,
        } = this.props;

        return {
            state,
            onFormError,
            handleForgotPassword,
            handleCreateAccount,
            isCheckout,
            setLoadingState,
            emailValue,
            handleEmailInput,
            isConsents,
            consents,
            storeCode,
        };
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInContainer);
