import { CLOSE_SIDEBAR, OPEN_SIDEBAR, TOGGLE_SIDEBAR } from 'Store/Sidebar/Sidebar.action';

/** @namespace RokitaBasic/Store/Sidebar/Reducer/getInitialState */
export const getInitialState = () => ({
    open: false,
    type: null,
});

/** @namespace RokitaBasic/Store/Sidebar/Reducer/SidebarReducer */
export const SidebarReducer = (state = getInitialState(), action) => {
    const { type: actionType, options } = action;
    const { type: sidebarType = null } = options ?? {};

    switch (actionType) {
        case TOGGLE_SIDEBAR:
            return {
                ...state,
                open: !state.open,
                type: !state.open === true ? sidebarType : null,
            };
        case OPEN_SIDEBAR:
            return {
                ...state,
                open: true,
                type: sidebarType,
            };
        case CLOSE_SIDEBAR:
            return {
                ...state,
                open: false,
                type: null,
            };
        default:
            return state;
    }
};

export default SidebarReducer;
