import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import transformToNameValuePair from 'Util/Form/Transform';

import MyAccountVerification from './MyAccountVerification.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace RokitaBasic/Component/MyAccountVerification/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace RokitaBasic/Component/MyAccountVerification/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    verification: (options) =>
        MyAccountDispatcher.then(({ default: dispatcher }) => dispatcher.verification(options, dispatch)),
    generateVerificationToken: () =>
        MyAccountDispatcher.then(({ default: dispatcher }) => dispatcher.generateVerificationToken(dispatch)),
});

/** @namespace RokitaBasic/Component/MyAccountVerification/Container */
export class MyAccountVerificationContainer extends PureComponent {
    containerFunctions = {
        onSubmit: this.onSubmit.bind(this),
        onGenerateVerificationToken: this.onGenerateVerificationToken.bind(this),
    };

    async onSubmit(_, fields) {
        const { verification, setLoadingState } = this.props;

        setLoadingState(true);

        const data = transformToNameValuePair(fields);

        try {
            await verification(data.code);
            setLoadingState(false);
        } catch (error) {
            setLoadingState(false);
        }
    }

    async onGenerateVerificationToken() {
        const { generateVerificationToken, setLoadingState } = this.props;

        setLoadingState(true);

        try {
            await generateVerificationToken();
            setLoadingState(false);
        } catch (error) {
            setLoadingState(false);
        }
    }

    containerProps = () => {
        const { onFormError, setLoadingState } = this.props;

        return {
            onFormError,
            setLoadingState,
        };
    };

    render() {
        return <MyAccountVerification {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountVerificationContainer);
