import Check from '@rokita/permissions';
import { cloneElement, createElement, lazy } from 'react';
import { Route } from 'react-router-dom';

import Authorization from 'Component/Authorization';
import {
    ACCOUNT_FORGOT_PASSWORD,
    ADDRESS_POPUP,
    AFTER_ITEMS_TYPE,
    ARRANGEMENT,
    ARRANGEMENT_PRODUCT_PAGE,
    BEFORE_ITEMS_TYPE,
    BREADCRUMBS,
    CART,
    CHANGE_CART_TYPE_POPUP,
    CHANGE_PASSWORD,
    CHECKOUT_SUCCESS,
    COOKIE_POPUP,
    DEMO_NOTICE,
    FOOTER,
    HEADER,
    HOME,
    LOGIN,
    LOGIN_AS_USER,
    NEW_VERSION_POPUP,
    NOTIFICATION_LIST,
    SEARCH,
    SIDEBAR,
    SWITCH_ITEMS_TYPE,
    URL_REWRITES,
    USER_SUPPORT_POPUP,
    VERIFICATION,
} from 'Component/Router/Router.config';
import { ARRANGEMENT_URL } from 'Route/ArrangementPage/ArrangementPage.config';
import { CART_SUCCESS_URL, CART_URL } from 'Route/CartPage/CartPage.config';
import { HOME_PAGE_URL } from 'Route/HomePage/HomePage.config';
import {
    ACCOUNT_ARRANGEMENTS_URL,
    ACCOUNT_CHANGE_PASSWORD_URL,
    ACCOUNT_FINANCIAL_DOCUMENT_URL,
    ACCOUNT_FORGOT_PASSWORD_URL,
    ACCOUNT_FORMS_URL,
    ACCOUNT_LOGIN_AS_USER_URL,
    ACCOUNT_LOGIN_URL,
    ACCOUNT_ORDER_HISTORY_URL,
    ACCOUNT_PARTNERS_URL,
    ACCOUNT_PRICE_LIST_URL,
    ACCOUNT_USER_MANAGE_URL,
    ACCOUNT_VERIFICATION_URL,
    PERMISSIONS,
} from 'Route/MyAccount/MyAccount.config';
import { MyAccountContainer } from 'Route/MyAccount/MyAccount.container';
import { SEARCH_URL } from 'Route/SearchPage/SearchPage.config';
import UrlRewrites from 'Route/UrlRewrites';
import { Router as SourceRouter, withStoreRegex } from 'SourceComponent/Router/Router.component';
import {
    ARRANGEMENTS,
    FINANCIAL_DOCUMENT,
    FORMS,
    ORDER_HISTORY,
    PARTNERS,
    PRICE_LIST,
    USER_MANAGE,
} from 'Type/Account.type';
import { joinTextWithSeparator } from 'Util/Text';

export * from 'SourceComponent/Router/Router.component';

export const DemoNotice = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/DemoNotice')
);
export const Header = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "header" */ 'Component/Header'));

export const Footer = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "footer" */ 'Component/Footer'));

export const Idle = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "idle" */ 'Component/Idle'));

export const NewVersionPopup = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/NewVersionPopup')
);
export const AddressPopup = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "address" */ 'Component/AddressPopup')
);
export const UserSupportPopup = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "user-support" */ 'Component/UserSupportPopup')
);
export const NotificationList = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/NotificationList')
);

export const Breadcrumbs = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "header" */ 'Component/Breadcrumbs')
);
export const CookiePopup = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/CookiePopup')
);
export const ChangeCartTypePopup = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/ChangeCartTypePopup')
);
export const CheckoutSuccess = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "cart" */ 'Component/CheckoutSuccess')
);

export const Sidebar = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "sidebar" */ 'Component/Sidebar'));

export const CartPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cart" */ 'Route/CartPage'));
export const Checkout = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "checkout" */ 'Route/Checkout'));
export const CmsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/CmsPage'));

export const HomePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/HomePage'));
export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'));
export const PasswordChangePage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/PasswordChangePage')
);
export const SearchPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "search" */ 'Route/SearchPage'));

export const LoginAccountPage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/LoginAccount')
);
export const VerificationAccount = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "verification" */ 'Route/VerificationAccount')
);
export const ForgotPasswordPage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/ForgotPassword')
);
export const LoginAsUserPage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "loginasuser" */ 'Route/LoginAsUserPage')
);
export const StyleGuidePage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/StyleGuidePage')
);
export const ArrangementPage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "arrangement" */ 'Route/ArrangementPage')
);
export const ArrangementProductPage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "arrangement-product" */ 'Route/ArrangementProductPage')
);

/** @namespace RokitaBasic/Component/Router/Component */
export class Router extends SourceRouter {
    [BEFORE_ITEMS_TYPE] = [
        {
            component: <NotificationList />,
            position: 10,
            name: NOTIFICATION_LIST,
        },
        {
            component: <DemoNotice />,
            position: 20,
            name: DEMO_NOTICE,
        },
        {
            component: <Header />,
            position: 30,
            name: HEADER,
        },
        {
            component: <Breadcrumbs />,
            position: 40,
            name: BREADCRUMBS,
        },
    ];

    [AFTER_ITEMS_TYPE] = [
        {
            component: <Footer />,
            position: 10,
            name: FOOTER,
        },
        {
            component: <CookiePopup />,
            position: 20,
            name: COOKIE_POPUP,
        },
        {
            component: <NewVersionPopup />,
            position: 30,
            name: NEW_VERSION_POPUP,
        },
        {
            component: <Sidebar tabMap={MyAccountContainer.tabMap} title={__('My account')} root />,
            position: 50,
            name: SIDEBAR,
        },
        {
            component: <ChangeCartTypePopup />,
            position: 60,
            name: CHANGE_CART_TYPE_POPUP,
        },
        {
            component: <AddressPopup />,
            position: 70,
            name: ADDRESS_POPUP,
        },
        {
            component: <UserSupportPopup />,
            position: 80,
            name: USER_SUPPORT_POPUP,
        },
        {
            component: <Idle />,
            position: 80,
            name: USER_SUPPORT_POPUP,
        },
    ];

    [SWITCH_ITEMS_TYPE] = [
        {
            component: <Route path={withStoreRegex(HOME_PAGE_URL)} exact render={(props) => <HomePage {...props} />} />,
            position: 10,
            name: HOME,
        },
        {
            component: (
                <Route
                    path={withStoreRegex(joinTextWithSeparator([SEARCH_URL, ':query'], '/'))}
                    render={(props) => <SearchPage {...props} />}
                />
            ),
            position: 20,
            name: SEARCH,
            permissions: PERMISSIONS.PRODUCT_LIST,
        },
        {
            component: <Route path={withStoreRegex(CART_URL)} exact render={(props) => <CartPage {...props} />} />,
            position: 30,
            name: CART,
            permissions: () =>
                Check.permissions(PERMISSIONS.ORDER_PLACEMENT_TRADE_AGREEMENT) ||
                Check.permissions(PERMISSIONS.ORDER_PLACEMENT_PRICE_LIST),
        },
        {
            component: (
                <Route
                    path={withStoreRegex(CART_SUCCESS_URL)}
                    exact
                    render={(props) => <CheckoutSuccess {...props} />}
                />
            ),
            position: 40,
            name: CHECKOUT_SUCCESS,
            permissions: () =>
                Check.permissions(PERMISSIONS.ORDER_PLACEMENT_TRADE_AGREEMENT) ||
                Check.permissions(PERMISSIONS.ORDER_PLACEMENT_PRICE_LIST),
        },
        {
            component: (
                <Route
                    path={withStoreRegex(ACCOUNT_CHANGE_PASSWORD_URL)}
                    render={(props) => <PasswordChangePage {...props} />}
                />
            ),
            position: 50,
            name: CHANGE_PASSWORD,
        },
        {
            component: (
                <Route path={withStoreRegex(ACCOUNT_LOGIN_URL)} render={(props) => <LoginAccountPage {...props} />} />
            ),
            position: 52,
            name: LOGIN,
        },
        {
            component: (
                <Route
                    path={withStoreRegex(ACCOUNT_VERIFICATION_URL)}
                    render={(props) => <VerificationAccount {...props} />}
                />
            ),
            position: 53,
            name: VERIFICATION,
        },
        {
            component: (
                <Route
                    path={withStoreRegex(ACCOUNT_FORGOT_PASSWORD_URL)}
                    render={(props) => <ForgotPasswordPage {...props} />}
                />
            ),
            position: 54,
            name: ACCOUNT_FORGOT_PASSWORD,
        },
        {
            component: (
                <Route
                    path={withStoreRegex(joinTextWithSeparator([ACCOUNT_LOGIN_AS_USER_URL, ':hash'], '/'))}
                    render={(props) => <LoginAsUserPage {...props} />}
                />
            ),
            position: 55,
            name: LOGIN_AS_USER,
        },
        {
            component: (
                <Route
                    path={withStoreRegex(ACCOUNT_PARTNERS_URL)}
                    render={(props) => <MyAccount selectedTab={PARTNERS} {...props} />}
                />
            ),
            position: 56,
            name: PARTNERS,
        },
        {
            component: (
                <Route
                    path={withStoreRegex(ACCOUNT_ORDER_HISTORY_URL)}
                    render={(props) => <MyAccount selectedTab={ORDER_HISTORY} {...props} />}
                />
            ),
            position: 56,
            name: ORDER_HISTORY,
        },
        {
            component: (
                <Route
                    path={withStoreRegex(ACCOUNT_FINANCIAL_DOCUMENT_URL)}
                    render={(props) => <MyAccount selectedTab={FINANCIAL_DOCUMENT} {...props} />}
                />
            ),
            position: 56,
            name: ORDER_HISTORY,
        },
        {
            component: (
                <Route
                    path={withStoreRegex(ACCOUNT_FINANCIAL_DOCUMENT_URL)}
                    render={(props) => <MyAccount selectedTab={FINANCIAL_DOCUMENT} {...props} />}
                />
            ),
            position: 56,
            name: FINANCIAL_DOCUMENT,
        },
        {
            component: (
                <Route
                    path={withStoreRegex(joinTextWithSeparator([ACCOUNT_ARRANGEMENTS_URL, ':params*'], '/'))}
                    render={(props) => <MyAccount selectedTab={ARRANGEMENTS} {...props} />}
                />
            ),
            position: 56,
            name: ARRANGEMENTS,
        },
        {
            component: (
                <Route
                    path={withStoreRegex(ACCOUNT_PRICE_LIST_URL)}
                    render={(props) => <MyAccount selectedTab={PRICE_LIST} {...props} />}
                />
            ),
            position: 56,
            name: PRICE_LIST,
        },
        {
            component: (
                <Route
                    path={withStoreRegex(ACCOUNT_FORMS_URL)}
                    render={(props) => <MyAccount selectedTab={FORMS} {...props} />}
                />
            ),
            position: 56,
            name: FORMS,
        },
        {
            component: (
                <Route
                    path={withStoreRegex(ACCOUNT_USER_MANAGE_URL)}
                    render={(props) => <MyAccount selectedTab={USER_MANAGE} {...props} />}
                />
            ),
            position: 56,
            name: USER_MANAGE,
        },

        {
            component: (
                <Route
                    exact
                    path={withStoreRegex(joinTextWithSeparator([ARRANGEMENT_URL, ':tradeAgreementId'], '/'))}
                    render={(props) => <ArrangementPage {...props} />}
                />
            ),
            position: 90,
            name: ARRANGEMENT,
            permissions: PERMISSIONS.TRADE_AGREEMENT,
        },
        {
            component: (
                <Route
                    exact
                    path={withStoreRegex(
                        joinTextWithSeparator([ARRANGEMENT_URL, ':tradeAgreementItemId', ':productUrl'], '/')
                    )}
                    render={(props) => <ArrangementProductPage {...props} />}
                />
            ),
            position: 91,
            name: ARRANGEMENT_PRODUCT_PAGE,
            permissions: PERMISSIONS.TRADE_AGREEMENT,
        },
        {
            component: <Route render={(props) => <UrlRewrites {...props} />} />,
            position: 1000,
            name: URL_REWRITES,
        },
    ];

    renderComponentsOfType(type) {
        return this.getSortedItems(type).map(({ name, position, component }) => {
            if (type === SWITCH_ITEMS_TYPE) {
                return cloneElement(component, {
                    key: position,
                    render: (props) => createElement(Authorization, { name, type }, component.props.render(props)),
                });
            }

            return createElement(Authorization, { name, key: position, type }, component);
        });
    }
}

export default Router;
