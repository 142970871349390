import PartnersQuery from 'Query/Partnerts.query';
import ProductListQuery from 'Query/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace RokitaBasic/Query/TradeAgreements/Query */
export class TradeAgreementsQuery {
    getTradeAgreements(filter = {}, sort = {}, pageSize = 300, currentPage = 1) {
        return new Field('getTradeAgreements')
            .setAlias('tradeAgreements')
            .addArgument('filter', 'TradeAgreementFilterInput', filter)
            .addArgument('sort', 'TradeAgreementSortInput', sort)
            .addArgument('pageSize', 'Int', pageSize)
            .addArgument('currentPage', 'Int', currentPage)
            .addFieldList([
                'total_count',
                new Field('page_info').addFieldList(['current_page', 'page_size', 'total_pages']),
                new Field('trade_agreements').setAlias('data').addFieldList(this._getTradeAgreementsFields()),
            ]);
    }

    getTradeAgreementRequestDeliveryDates(tradeAgreementItems) {
        return new Field('getTARequestDeliveryDates')
            .setAlias('tradeAgreementRequestDeliveryDates')
            .addArgument('taCartItems', '[TARequestDeliveryDatesInput]!', tradeAgreementItems)
            .addFieldList(['start_date', 'end_date', 'available_dates']);
    }

    _getTradeAgreementsFields(
        { withProduct = true, withContractor = true } = { withProduct: true, withContractor: true }
    ) {
        const fields = [
            'active',
            'contractor_id_ag',
            'contractor_id_rg',
            'contractor_id_ze',
            'contractor_id_zh',
            'customer_order_number',
            'document_date',
            'id',
            'payment_terms',
            'quotation_number',
            'request_delivery_date',
            'sales_organization',
            'valid_from',
            'valid_to',
        ];

        if (withProduct) {
            fields.push(new Field('items').addFieldList(this._getTradeAgreementsItemFields()));
        }

        if (withContractor) {
            fields.push(new Field('contractor_zh').addFieldList(PartnersQuery._getPartnersFields()));
        }

        return fields;
    }

    _getTradeAgreementsItemFields(
        { withProduct = true, withContractor = true } = { withProduct: true, withContractor: true }
    ) {
        const fields = [
            'active',
            'amount',
            'billing_block',
            'condition_pricing_unit',
            'condition_unit',
            'contractor_id_we',
            'contractor_id_zm',
            'currency',
            'customer_material',
            'id',
            'incoterms',
            'incoterms_loc',
            'item',
            'material',
            'open_quantity',
            'plant',
            'qty_increments',
            'quotation_number',
            'reason_for_rejection',
            'sales_unit',
            'trade_agreement_id',
        ];

        if (withProduct) {
            fields.push(new Field('material_product').addFieldList(this._getTradeAgreementsMaterialField()));
        }

        if (withContractor) {
            fields.push(new Field('contractor_we').addFieldList(PartnersQuery._getPartnersFields()));
        }

        return fields;
    }

    _getTradeAgreementsMaterialField() {
        return ProductListQuery._getProductInterfaceFields(false);
    }
}

export default new TradeAgreementsQuery();
