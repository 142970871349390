import PropTypes from 'prop-types';

import { Loader as SourceLoader } from 'SourceComponent/Loader/Loader.component';

import './Loader.style';

/** @namespace RokitaBasic/Component/Loader/Component */
export class Loader extends SourceLoader {
    static propTypes = {
        ...SourceLoader.propTypes,
        isFullPage: PropTypes.bool,
        isAttachmentLoader: PropTypes.bool,
    };

    static defaultProps = {
        ...SourceLoader.defaultProps,
        isFullPage: false,
        isAttachmentLoader: false,
    };

    componentDidMount() {
        const { isFullPage } = this.props;

        if (isFullPage) {
            document.documentElement.classList.add('isFullPageLoader');
        }
    }

    componentDidUpdate() {
        const { isFullPage, isAttachmentLoader, isLoading } = this.props;

        if (isLoading) {
            if (isAttachmentLoader) {
                document.documentElement.classList.add('isAttachmentLoader');
            }

            if (isFullPage) {
                document.documentElement.classList.add('isFullPageLoader');
            }
        } else {
            document.documentElement.classList.remove('isAttachmentLoader');
            document.documentElement.classList.remove('isFullPageLoader');
        }
    }

    componentWillUnmount() {
        document.documentElement.classList.remove('isAttachmentLoader');
        document.documentElement.classList.remove('isFullPageLoader');
    }

    render() {
        const { isLoading, isFullPage } = this.props;

        if (!isLoading) {
            return null;
        }

        return (
            <div block="Loader" mods={{ isFullPage }}>
                <div block="Loader" elem="Scale">
                    {this.renderMain()}
                </div>
            </div>
        );
    }
}

export default Loader;
