export const GET_FINANCIAL_DOCUMENT_LIST = 'GET_FINANCIAL_DOCUMENT_LIST';
export const GET_FINANCIAL_DOCUMENT_LOADING = 'GET_FINANCIAL_DOCUMENT_LOADING';

/** @namespace RokitaBasic/Store/FinancialDocument/Action/getFinancialDocumentList */
export const getFinancialDocumentList = (data) => ({
    type: GET_FINANCIAL_DOCUMENT_LIST,
    data,
});

/** @namespace RokitaBasic/Store/FinancialDocument/Action/getFinancialDocumentLoading */
export const getFinancialDocumentLoading = () => ({
    type: GET_FINANCIAL_DOCUMENT_LOADING,
});
