import { endOfDay, startOfDay } from 'date-fns';
import pick from 'lodash/pick';
import setWith from 'lodash/setWith';
import qs from 'qs';

import FIELD_TYPE from 'Component/Field/Field.config';
import { FIELD_SELECT_DEFAULT_VALUE } from 'Component/FieldSelect/FieldSelect.config';
import { format, parseDate } from 'Util/Date';
import { flattenObject } from 'Util/Object';
import { QUERY_FILTER_NAME } from 'Util/Url/Url';

export const DEFAULT_FILTER_VALUE = '';

export const FILTER_CONDITIONS = {
    eq: 'eq',
    finset: 'finset',
    from: 'from',
    gt: 'GT',
    gteq: 'gteq',
    in: 'in',
    like: 'like',
    lt: 'lt',
    lteq: 'lteq',
    moreq: 'moreq',
    neq: 'neq',
    nin: 'nin',
    notnull: 'notnull',
    null: 'null',
    to: 'to',
};

/** @namespace RokitaBasic/Util/Filter/getConditionKey */
export const getConditionKey = (value) => `.${value}`;

/** @namespace RokitaBasic/Util/Filter/prepareFilterValuesFromUrl */
export const prepareFilterValuesFromUrl = (location) => {
    const filter = {};

    const { search = '' } = location ?? {};

    if (!search) {
        return filter;
    }

    const filterValuesFromUrl = flattenObject(
        (qs.parse(search ?? '', {
            ignoreQueryPrefix: true,
            comma: true,
        }) ?? {})[QUERY_FILTER_NAME] ?? {}
    );

    for (const key of Object.keys(filterValuesFromUrl ?? {})) {
        const name = key.replace(
            new RegExp(
                `(${Object.values(FILTER_CONDITIONS)
                    .map((condition) => getConditionKey(condition))
                    .join('|')})$`,
                'g'
            ),
            DEFAULT_FILTER_VALUE
        );

        if (key.includes(getConditionKey(FILTER_CONDITIONS.in))) {
            filter[name] = filterValuesFromUrl[key].split(',');
        } else if (key.includes(getConditionKey(FILTER_CONDITIONS.from))) {
            if (name in filter) {
                filter[name] = [filterValuesFromUrl[key], ...filter[name]];
            } else {
                filter[name] = [filterValuesFromUrl[key]];
            }
        } else if (key.includes(getConditionKey(FILTER_CONDITIONS.to))) {
            if (name in filter) {
                filter[name] = [...filter[name], filterValuesFromUrl[key]];
            } else {
                filter[name] = [filterValuesFromUrl[key]];
            }
        } else if (key.includes(getConditionKey(FILTER_CONDITIONS.like))) {
            filter[name] = filterValuesFromUrl[key].slice(1, -1);
        } else {
            filter[name] = filterValuesFromUrl[key];
        }
    }

    return filter;
};

/** @namespace RokitaBasic/Util/Filter/getFieldAttributes */
export const getFieldAttributes = (name, filter = []) => {
    if (!name) return {};

    return pick(
        filter?.find(({ attr }) => {
            const { name: attrName } = attr ?? {};
            return attrName === name;
        }) ?? {},
        ['condition', 'type']
    );
};

/** @namespace RokitaBasic/Util/Filter/prepareFilter */
export const prepareFilter = (filters, definedFilter = []) => {
    return Object.entries(filters)
        .filter(([, value]) => Boolean(value?.value ?? value))
        .map(([key, value]) => ({ key, value: value?.value ?? value, ...getFieldAttributes(key, definedFilter) }))
        .reduce((previous, { key, value, condition, type }) => {
            switch (type) {
                case FIELD_TYPE.dateRange: {
                    const [from, to] = Array.isArray(value) ? value : value.split(' - ');

                    if (!from && !to) {
                        return previous;
                    }

                    return [
                        ...previous,
                        ...(from
                            ? [
                                  {
                                      key,
                                      condition: FILTER_CONDITIONS.from,
                                      value: format(startOfDay(parseDate(from)), 'yyyy-MM-dd HH:mm:ss'),
                                  },
                              ]
                            : []),
                        ...(to
                            ? [
                                  {
                                      key,
                                      condition: FILTER_CONDITIONS.to,
                                      value: format(endOfDay(parseDate(to)), 'yyyy-MM-dd HH:mm:ss'),
                                  },
                              ]
                            : []),
                    ];
                }
                case FIELD_TYPE.date: {
                    return [
                        ...previous,
                        {
                            key,
                            condition,
                            value: format(value?.value ?? value, 'yyyy-MM-dd'),
                        },
                    ];
                }
                default: {
                    return [
                        ...previous,
                        { key, condition, value: FIELD_SELECT_DEFAULT_VALUE === value ? undefined : value },
                    ];
                }
            }
        }, [])
        .reduce((previous, { key, value, condition }) => {
            return setWith(
                previous,
                `${key}.${FILTER_CONDITIONS[condition] ?? FILTER_CONDITIONS.eq}`,
                FILTER_CONDITIONS[condition] === FILTER_CONDITIONS.like ? `%${value}%` : value,
                Object
            );
        }, {});
};
