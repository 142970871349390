import React, { PureComponent } from 'react';

import './WarningIcon.style';

/** @namespace RokitaBasic/Component/WarningIcon/Component */
export class WarningIcon extends PureComponent {
    render() {
        return (
            <svg
                block="WarningIcon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 21"
                fill="none"
            >
                <path
                    d="M10.7144 16.0714C10.7144 16.4124 10.8498 16.7394 11.0909 16.9806C11.332 17.2217 11.659 17.3571 12 17.3571C12.341 17.3571 12.668 17.2217 12.9091 16.9806C13.1502 16.7394 13.2856 16.4124 13.2856 16.0714C13.2856 15.7304 13.1502 15.4034 12.9091 15.1623C12.668 14.9212 12.341 14.7857 12 14.7857C11.659 14.7857 11.332 14.9212 11.0909 15.1623C10.8498 15.4034 10.7144 15.7304 10.7144 16.0714ZM11.1429 7.92857V12.8571C11.1429 12.975 11.2393 13.0714 11.3572 13.0714H12.6428C12.7607 13.0714 12.8571 12.975 12.8571 12.8571V7.92857C12.8571 7.81071 12.7607 7.71429 12.6428 7.71429H11.3572C11.2393 7.71429 11.1429 7.81071 11.1429 7.92857ZM23.8841 19.7143L12.7419 0.428571C12.5759 0.141964 12.2893 0 12 0C11.7107 0 11.4215 0.141964 11.2581 0.428571L0.115851 19.7143C-0.213595 20.2875 0.198882 21 0.857773 21H23.1422C23.8011 21 24.2136 20.2875 23.8841 19.7143ZM2.89873 18.967L12 3.21161L21.1013 18.967H2.89873Z"
                    fill="currentColor"
                />
            </svg>
        );
    }
}

export default WarningIcon;
