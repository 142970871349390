import enGB from 'date-fns/locale/en-GB';
import enUS from 'date-fns/locale/en-US';
import pl from 'date-fns/locale/pl';
import PropTypes from 'prop-types';
import React, { createRef, PureComponent } from 'react';
import { registerLocale } from 'react-datepicker';
import { connect } from 'react-redux';

import { EventsType, FieldAttrType } from 'Type/Field.type';
import { parseDate } from 'Util/Date';

import FieldDatePicker from './FieldDatePicker.component';
import { DEFAULT_VALUE } from './FieldDatePicker.config';

registerLocale('en_GB', enGB);
registerLocale('en_US', enUS);
registerLocale('pl_PL', pl);

/** @namespace RokitaBasic/Component/FieldDatePicker/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    currentStoreCode: state.ConfigReducer.code,
    locale: state.ConfigReducer.locale,
});

/** @namespace RokitaBasic/Component/FieldDatePicker/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace RokitaBasic/Component/FieldDatePicker/Container */
export class FieldDatePickerContainer extends PureComponent {
    static propTypes = {
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        setRef: PropTypes.func.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        selectsRange: PropTypes.bool,
        customInput: PropTypes.node,
    };

    static defaultProps = {
        selectsRange: false,
        customInput: null,
    };

    static getValue(props) {
        const { attr: { defaultValue, value } = {}, selectsRange } = props;

        if (value) {
            if (Array.isArray(value)) {
                return [parseDate(value[0]) ?? DEFAULT_VALUE, parseDate(value[1]) ?? DEFAULT_VALUE];
            }

            return parseDate(value) ?? DEFAULT_VALUE;
        }

        if (defaultValue) {
            if (Array.isArray(defaultValue)) {
                return [parseDate(defaultValue[0]) ?? DEFAULT_VALUE, parseDate(defaultValue[1]) ?? DEFAULT_VALUE];
            }

            return parseDate(defaultValue) ?? DEFAULT_VALUE;
        }

        return selectsRange ? [DEFAULT_VALUE, DEFAULT_VALUE] : DEFAULT_VALUE;
    }

    containerFunctions = {
        onClose: this.onClose.bind(this),
        onEvent: this.onEvent.bind(this),
        onReference: this.onReference.bind(this),
    };

    datepickerRef = createRef();

    inputRef = createRef();

    componentDidMount() {
        if (typeof this.inputRef.current.addEventListener === 'function') {
            this.inputRef.current.addEventListener('resetField', this.onReset.bind(this));
        }
    }

    componentDidUpdate(prevProps) {
        const { attr: { defaultValue, value } = {} } = this.props;
        const { attr: { defaultValue: prevDefaultValue, value: prevValue } = {} } = prevProps;

        if (prevValue !== value || prevDefaultValue !== defaultValue) {
            this.setState({
                value: FieldDatePickerContainer.getValue(this.props),
            });
        }
    }

    componentWillUnmount() {
        if (typeof this.inputRef.current.addEventListener === 'function') {
            this.inputRef.current.removeEventListener('resetField', this.onReset.bind(this));
        }
    }

    __construct() {
        this.state = {
            value: FieldDatePickerContainer.getValue(this.props),
        };
    }

    containerProps() {
        const {
            attr: { disabled } = {},
            label,
            attr,
            events,
            isDisabled,
            setRef,
            selectsRange,
            locale,
            customInput,
        } = this.props;
        const { value } = this.state;

        return {
            attr,
            label,
            events,
            disabled: disabled || isDisabled,
            setRef,
            value,
            selectsRange,
            locale: locale || window.defaultLocale,
            customInput,
        };
    }

    onReference(reference) {
        const { setRef } = this.props;
        const { input } = reference ?? {};
        if (input) {
            if (this.inputRef.current !== input) {
                this.inputRef.current = input;
            }

            if (this.datepickerRef.current !== reference) {
                this.datepickerRef.current = reference;
            }

            if (typeof setRef === 'function') {
                setRef(input);
            }
        }
    }

    onClose() {
        if (this.datepickerRef.current) {
            this.datepickerRef.current.setOpen(false);
        }
    }

    onEvent(eventName) {
        return (value, event) => {
            const { events = {} } = this.props;

            if (eventName === 'onChange') {
                this.setState({ value });
            }

            if (typeof events[eventName] === 'function') {
                events[eventName](value, event);
            }
        };
    }

    onReset() {
        this.onEvent('onChange')(FieldDatePickerContainer.getValue(this.props), {});
    }

    render() {
        return <FieldDatePicker {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldDatePickerContainer);
