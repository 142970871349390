import { App as SourceApp } from 'SourceComponent/App/App.component';
import { environment } from 'Util/Environment';

/** @namespace RokitaBasic/Component/App/Component */
export class App extends SourceApp {
    injectComment() {
        const comment = document.createComment('Powered by ScandiPWA (scandipwa.com)');
        const version = document.createComment(environment.version);

        document.querySelector('html').appendChild(comment);
        document.querySelector('html').appendChild(version);
    }
}

export default App;
