import { PRODUCT_ATTRIBUTES_CODES } from 'Component/ProductAttributes/ProductAttributes.config';

export * from 'SourceUtil/Product/Product';

/** @namespace RokitaBasic/Util/Product/getAttributesWithValues */
export const getAttributesWithValues = (product) => {
    const { attributes = {}, parameters = {} } = product;

    return Object.entries(attributes).reduce((acc, [key, val]) => {
        const { attribute_label, attribute_code, attribute_value } = val;

        if (!PRODUCT_ATTRIBUTES_CODES.includes(attribute_code)) return acc;

        if (attribute_value) {
            return { ...acc, [attribute_label]: val };
        }

        const valueIndexFromParameter = parameters[key];

        if (valueIndexFromParameter) {
            return { ...acc, [attribute_label]: { ...val, attribute_value: valueIndexFromParameter } };
        }

        return acc;
    }, {});
};
