import React, { PureComponent } from 'react';

/** @namespace RokitaBasic/Component/SuccessIcon/Component */
export class SuccessIcon extends PureComponent {
    render() {
        return (
            <svg
                block="SuccessIcon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    d="M15.7031 8.20312L10.5 13.4531L8.25 11.2031C7.78125 10.7812 7.07812 10.7812 6.65625 11.2031C6.1875 11.6719 6.1875 12.375 6.65625 12.7969L9.65625 15.7969C9.89062 16.0312 10.1719 16.125 10.5 16.125C10.7812 16.125 11.0625 16.0312 11.25 15.7969L17.25 9.79688C17.7188 9.375 17.7188 8.67188 17.25 8.25C16.8281 7.78125 16.125 7.78125 15.7031 8.20312ZM12 0C5.34375 0 0 5.39062 0 12C0 18.6562 5.34375 24 12 24C18.6094 24 24 18.6562 24 12C24 5.39062 18.6094 0 12 0ZM12 21.75C6.60938 21.75 2.25 17.3906 2.25 12C2.25 6.65625 6.60938 2.25 12 2.25C17.3438 2.25 21.75 6.65625 21.75 12C21.75 17.3906 17.3438 21.75 12 21.75Z"
                    fill="currentColor"
                />
            </svg>
        );
    }
}

export default SuccessIcon;
