import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import './ErrorIcon.style';

/** @namespace RokitaBasic/Component/ErrorIcon/Component */
export class ErrorIcon extends PureComponent {
    static propTypes = {
        secondary: PropTypes.bool,
    };

    static defaultProps = {
        secondary: false,
    };

    render() {
        const { secondary } = this.props;

        if (secondary) {
            return (
                <svg
                    block="ErrorIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="60"
                    height="51"
                    viewBox="0 0 60 51"
                    fill="none"
                >
                    <path
                        d="M30.0003 10.973L50.0803 45.6663H9.92032L30.0003 10.973ZM30.0003 0.333008L0.666992 50.9997H59.3336L30.0003 0.333008ZM32.667 37.6663H27.3337V42.9997H32.667V37.6663ZM32.667 21.6663H27.3337V32.333H32.667V21.6663Z"
                        fill="currentColor"
                    />
                </svg>
            );
        }

        return (
            <svg
                block="ErrorIcon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    d="M15.75 8.20312C15.2812 7.78125 14.5781 7.78125 14.1562 8.20312L12 10.4531L9.75 8.20312C9.28125 7.78125 8.57812 7.78125 8.15625 8.20312C7.6875 8.67188 7.6875 9.375 8.15625 9.79688L10.3594 12L8.20312 14.2031C7.73438 14.6719 7.73438 15.375 8.20312 15.7969C8.625 16.2656 9.32812 16.2656 9.75 15.7969L12 13.5938L14.2031 15.7969C14.625 16.2656 15.3281 16.2656 15.75 15.7969C16.2188 15.375 16.2188 14.6719 15.75 14.2031L13.5469 12L15.75 9.79688C16.2188 9.375 16.2188 8.67188 15.75 8.20312ZM12 0C5.34375 0 0 5.39062 0 12C0 18.6562 5.34375 24 12 24C18.6094 24 24 18.6562 24 12C24 5.39062 18.6094 0 12 0ZM12 21.75C6.60938 21.75 2.25 17.3906 2.25 12C2.25 6.65625 6.60938 2.25 12 2.25C17.3438 2.25 21.75 6.65625 21.75 12C21.75 17.3906 17.3438 21.75 12 21.75Z"
                    fill="currentColor"
                />
            </svg>
        );
    }
}

export default ErrorIcon;
