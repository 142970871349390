import { CART_ID } from 'SourceUtil/Cart/Token';
import BrowserDatabase from 'Util/BrowserDatabase';

export * from 'SourceUtil/Cart/Token';

/** @namespace RokitaBasic/Util/Cart/Token/deleteCartId */
export const deleteCartId = () => {
    const { website_code } = window;

    const tokens = BrowserDatabase.getItem(CART_ID) || {};

    tokens[website_code] = undefined;
    BrowserDatabase.setItem(tokens, CART_ID);
};
