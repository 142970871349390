import PropTypes from 'prop-types';

import { ChevronIcon as SourceChevronIcon } from 'SourceComponent/ChevronIcon/ChevronIcon.component';

import 'Component/ChevronIcon/Chevronicon.override.style';

/** @namespace RokitaBasic/Component/ChevronIcon/Component */
export class ChevronIcon extends SourceChevronIcon {
    static propTypes = {
        secondary: PropTypes.bool,
    };

    static defaultProps = {
        secondary: false,
    };

    render() {
        const { direction, secondary } = this.props;

        if (secondary) {
            return (
                <svg
                    block="ChevronIcon"
                    mods={{ direction }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0.499024 7.00117C0.499023 7.51894 0.918756 7.93868 1.43652 7.93868L12.2984 7.93869L8.27277 11.9624C7.90619 12.329 7.90619 12.9234 8.27277 13.2899C8.63935 13.6565 9.23369 13.6565 9.60027 13.2899L15.2253 7.66494C15.4016 7.48906 15.5007 7.25024 15.5007 7.00119C15.5007 6.75214 15.4016 6.51332 15.2253 6.33744L9.60028 0.712434C9.2337 0.345855 8.63936 0.345854 8.27278 0.712433C7.9062 1.07901 7.9062 1.67335 8.27278 2.03993L12.2984 6.06369L1.43653 6.06368C0.918758 6.06368 0.499025 6.48341 0.499024 7.00117Z"
                        fill="currentColor"
                    />
                </svg>
            );
        }

        return (
            <svg
                block="ChevronIcon"
                mods={{ direction }}
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
            >
                <path
                    d="M1 13L7 7.00007L1 1"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
}

export default ChevronIcon;
