import PartnersQuery from 'Query/Partnerts.query';
import { CartQuery as SourceCartQuery } from 'SourceQuery/Cart.query';
import { Field } from 'Util/Query';

/** @namespace RokitaBasic/Query/Cart/Query */
export class CartQuery extends SourceCartQuery {
    getCartRequestDeliveryDates() {
        return new Field('getPriceListRequestDeliveryDates')
            .setAlias('cartRequestDeliveryDates')
            .addFieldList(['start_date', 'end_date']);
    }

    getAddCommentToCartItem(cartItemId, comment) {
        return new Field('setCustomerCommentOnCartItem')
            .addArgument('cartItemId', 'Int!', cartItemId)
            .addArgument('customerComment', 'String!', comment)
            .addField(this._getCartSuccessMutation());
    }

    getSetCartItemMaterialReceiver(contractorWeId, cartItemId) {
        return new Field('setCartItemMaterialReceiver')
            .addArgument('cartItemId', 'Int!', cartItemId)
            .addArgument('contractorWeId', 'Int!', contractorWeId)
            .addField(this._getCartSuccessMutation());
    }

    getSetCartItemExternalNumber(externalNumber, cartItemId) {
        return new Field('setCartItemExternalNumber')
            .addArgument('cartItemId', 'Int!', cartItemId)
            .addArgument('externalNumber', 'String!', externalNumber)
            .addField(this._getCartSuccessMutation());
    }

    getSetCartItemMoptAndDestinationCountry(contractorZmId, destinationCountry, cartItemId) {
        return new Field('setCartItemMoptAndDestinationCountry')
            .addArgument('cartItemId', 'Int!', cartItemId)
            .addArgument('contractorZmId', 'Int', contractorZmId)
            .addArgument('destinationCountry', 'String!', destinationCountry)
            .addField(this._getCartSuccessMutation());
    }

    setCartRequestDeliveryDate(requestDeliveryDate) {
        return new Field('setCartRequestDeliveryDate')
            .addArgument('requestDeliveryDate', 'String!', requestDeliveryDate)
            .addField(this._getCartSuccessMutation());
    }

    addAttachmentToCart({ filename, filetype, content }, key) {
        return new Field('addAttachmentToCart')
            .setAlias(key ? `addAttachmentToCart${key}` : 'addAttachmentToCart')
            .addArgument('filename', 'String!', filename)
            .addArgument('filetype', 'String!', filetype)
            .addArgument('content', 'String!', content)
            .addField(this._getCartSuccessMutation());
    }

    deleteAttachmentFromCart({ attachmentId }, key) {
        return new Field('deleteAttachmentFromCart')
            .setAlias(key ? `deleteAttachmentFromCart${key}` : 'deleteAttachmentFromCart')
            .addArgument('attachmentId', 'Int!', attachmentId)
            .addField(this._getCartSuccessMutation());
    }

    _getCartTotalsFields() {
        return [...super._getCartTotalsFields(), this._getCartAttachmentsField()];
    }

    _getCartItemsFields() {
        return [
            ...super._getCartItemsFields(),
            'customer_comment',
            'payment_terms',
            'contractor_id_we',
            'external_number',
            'contractor_id_zm',
            'destination_country',
            'request_delivery_date',
            this._getCartItemIncoterms(),
            this._getCartItemMaterialReceivers(),
        ];
    }

    _getCartItemMaterialReceivers() {
        return new Field('contractor_we').addFieldList(PartnersQuery._getPartnersFields());
    }

    _getCartItemIncoterms() {
        return new Field('incoterms_data').addFieldList(['incoterms', 'incoterms_loc']);
    }

    _getCartAttachmentsField() {
        return new Field('order_attachments').addFieldList(this._getCartAttachmentsFields());
    }

    _getCartAttachmentsFields() {
        return ['id', 'filetype', 'filename', 'content'];
    }

    _getCartSuccessMutation() {
        return new Field('cart').addFieldList(['id']);
    }
}

export default new CartQuery();
