import Consents from 'Component/Consents';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Link from 'Component/Link';
import Notification from 'Component/Notification/Notification.component';
import { MyAccountSignIn as SourceMyAccountSignIn } from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.component';
import { noopFn } from 'Util/Common';
import environment from 'Util/Environment';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import './MyAccountSignIn.style.scss';

/** @namespace RokitaBasic/Component/MyAccountSignIn/Component */
export class MyAccountSignIn extends SourceMyAccountSignIn {
    renderSignInForm() {
        const { onSignInSuccess, onFormError, emailValue, isCheckout, isConsents } = this.props;

        return (
            <Form key="sign-in" onSubmit={onSignInSuccess} onError={onFormError}>
                <Field
                    label={__('Email')}
                    type={FIELD_TYPE.email}
                    attr={{
                        id: 'email',
                        name: 'email',
                        placeholder: __('Your email address'),
                        defaultValue: emailValue,
                        autocomplete: isCheckout ? 'off' : 'email',
                    }}
                    isDisabled={isConsents}
                    validateOn={['onChange']}
                    validationRule={{
                        isRequired: true,
                        inputType: VALIDATION_INPUT_TYPE.email,
                    }}
                />
                <Field
                    label={__('Password')}
                    type={FIELD_TYPE.password}
                    attr={{
                        id: 'password',
                        name: 'password',
                        placeholder: __('Enter your password'),
                        autocomplete: 'current-password',
                    }}
                    isDisabled={isConsents}
                    validateOn={['onChange']}
                    validationRule={{
                        isRequired: true,
                        inputType: VALIDATION_INPUT_TYPE.password,
                    }}
                />
                <Link
                    to="account/forgotpassword"
                    block="Button"
                    mods={{ likeLink: true }}
                    mix={{ block: 'MyAccountOverlay', elem: 'ForgotPassword' }}
                >
                    {__('Forgot password?')}
                </Link>
                {this.renderConsentsForm()}
                <div block="MyAccountOverlay" elem="SignInButton">
                    <button block="Button">{isConsents ? __('Accept') : __('Sign in')}</button>
                </div>
                {this.renderAdditionalInfo()}
            </Form>
        );
    }

    renderAdditionalInfo() {
        const { storeCode } = this.props;

        const href = new URL(
            `/media/productattachment/p/c/pcc_b2b_terms_${storeCode}.pdf`,
            environment.magentoUrl ?? window.origin
        ).toString();

        return (
            <div block="MyAccountOverlay" elem="AdditionalInfo">
                {__('Signing up is tantamount to acceptance of ')}
                <a href={href} download target="__blank" rel="noreferrer noopener">
                    {__('terms and conditions')}
                </a>
            </div>
        );
    }

    renderConsentsForm() {
        const { isConsents, consents } = this.props;

        if (!isConsents) return null;

        return (
            <div block="MyAccountOverlay" elem="Consents">
                <Notification
                    onHideNotification={noopFn}
                    notification={{
                        msgText: __('We found consents that you must accept to log in'),
                        msgType: 'info',
                        msgDebug: null,
                    }}
                    lifeTime={-1}
                />
                <Consents data={consents} />
            </div>
        );
    }
}

export default MyAccountSignIn;
