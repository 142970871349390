import Loader from 'Component/Loader';
import ConfigQuery from 'Query/Config.query';
import { fetchQuery } from 'Util/Request/Query';

import Locale from '../util/Locale';

const I18nComponent_state = (originalMember) => ({
    ...originalMember,
    isLoading: true,
});

const I18nComponent_render = (args, callback, instance) => {
    const { isLoading } = instance.state;

    if (isLoading) {
        return <Loader isFullPage isLoading />;
    }

    // eslint-disable-next-line react/destructuring-assignment
    return callback.apply(instance, args);
};

const I18nComponent_componentDidMount = (args, callback, instance) => {
    fetchQuery(ConfigQuery.getStoreListField()).then(instance.setStoreList).catch(instance.setStoreList);
};

const I18nComponent_setStoreList = async (args, callback, instance) => {
    const { storeList = [{ code: 'pl' }] } = args[0] ?? {};

    if (storeList) {
        Locale.setStoreList(storeList);
    }

    if (!Locale.getWebsiteLocale()) {
        Locale.replaceLocale(Locale.locale);
        return;
    }

    if (!!Locale.getWebsiteLocale() && Locale.getWebsiteLocale() !== Locale.locale) {
        Locale.replaceLocale(Locale.locale);

        return;
    }

    if (
        !!Locale.getWebsiteLocale() &&
        Locale.getWebsiteLocale() !== Locale.locale &&
        Locale.getConfigLocale() !== Locale.locale
    ) {
        Locale.replaceLocale(Locale.locale);

        return;
    }

    Locale.initialize();

    instance.setState((prev) => ({
        ...prev,
        isLoading: false,
    }));
};

export default {
    'RuntimeI18n/Component/I18n/Component/I18nComponent': {
        'member-property': {
            state: I18nComponent_state,
        },
        'member-function': {
            render: I18nComponent_render,
            setStoreList: I18nComponent_setStoreList,
            componentDidMount: I18nComponent_componentDidMount,
        },
    },
};
