import PropTypes from 'prop-types';

import { TextPlaceholder as SourceTextPlaceholder } from 'SourceComponent/TextPlaceholder/TextPlaceholder.component';

import './TextPlaceholder.style';

/** @namespace RokitaBasic/Component/TextPlaceholder/Component */
export class TextPlaceholder extends SourceTextPlaceholder {
    static propTypes = {
        ...SourceTextPlaceholder.propTypes,
        count: PropTypes.number,
    };

    getLengthArray(length) {
        const { count } = this.props;

        switch (length) {
            case 'paragraph': {
                return Array.from({ length: count ?? 2 });
            }
            default: {
                return Array.from({ length: count ?? 1 });
            }
        }
    }

    render() {
        const { content, length, mix } = this.props;

        if (content) {
            return content;
        }

        return (
            <span block="TextPlaceholder">
                {this.getLengthArray(length).map((_, key) => (
                    <span key={key} mix={mix} block="TextPlaceholder" elem="Item" mods={{ length }} />
                ))}
            </span>
        );
    }
}

export default TextPlaceholder;
