import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    CONSENTS_CHECKOUT,
    CONSENTS_CONTACT,
    CONSENTS_NEWSLETTER,
    CONSENTS_REGISTER,
} from 'Component/Consents/Consents.config';
import FIELD_TYPE from 'Component/Field/Field.config';
import ConsentsQuery from 'Query/Consents.query';
import { getCartId } from 'Util/Cart';
import { noopFn } from 'Util/Common';
import { prepareQuery } from 'Util/Query';
import { executePost } from 'Util/Request';

import Consents from './Consents.component';

/** @namespace RokitaBasic/Component/Consents/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    priceListCartId: getCartId(),
    isTradeAgreementCart: state.TradeAgreementCartReducer.tradeAgreementCartTotals.items.length > 0,
    tradeAgreementCartId: state.TradeAgreementCartReducer.tradeAgreementCartTotals.trade_agreement_cart_id,
});

/** @namespace RokitaBasic/Component/Consents/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace RokitaBasic/Component/Consents/Container */
export class ConsentsContainer extends PureComponent {
    static propTypes = {
        type: PropTypes.oneOf([CONSENTS_REGISTER, CONSENTS_CONTACT, CONSENTS_NEWSLETTER, CONSENTS_CHECKOUT]),
        data: PropTypes.arrayOf(
            PropTypes.shape({
                consent_id: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                is_required: PropTypes.bool.isRequired,
                is_checkbox: PropTypes.bool.isRequired,
            })
        ),
        setRef: PropTypes.func,
    };

    static defaultProps = {
        data: null,
        setRef: noopFn,
    };

    static async getConsentsByType(type, options = {}) {
        if (!type) throw new Error('Consents type is required');

        try {
            return (await executePost(prepareQuery(ConsentsQuery.getConsents(type, options)))).consents;
        } catch {
            return Promise.resolve([]);
        }
    }

    static getAcceptedConsents({ fields = [], consents = [] } = {}) {
        const checkbox = fields
            .filter((field) => field.type === FIELD_TYPE.checkbox && field.value === true)
            .map((consent) => consent.name);
        const text = (consents ?? []).filter((consent) => !consent.is_checkbox).map((consent) => consent.consent_id);

        return [...checkbox, ...text];
    }

    componentDidMount() {
        const { data, type } = this.props;

        if (data) {
            this.setState({ data });
        } else if (!data && type) {
            this.initialize();
        }
    }

    __construct() {
        const { setRef } = this.props;

        this.state = {
            data: [],
        };

        setRef({
            initialize: this.initialize.bind(this),
        });
    }

    async initialize() {
        const { type, priceListCartId, isTradeAgreementCart, tradeAgreementCartId } = this.props;

        this.setState({
            data: await ConsentsContainer.getConsentsByType(
                type,
                type === CONSENTS_CHECKOUT
                    ? {
                          cartId: tradeAgreementCartId ?? priceListCartId,
                          isTaCheckout: isTradeAgreementCart,
                      }
                    : {}
            ),
        });
    }

    containerProps() {
        const { data } = this.state;

        return {
            data,
        };
    }

    render() {
        return <Consents {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsentsContainer);
