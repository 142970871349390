/** @namespace RokitaBasic/Util/Environment/getEnvironment */
export const getEnvironment = (environment = process.env) => {
    if (!environment) throw new Error('Environment not found!');

    return {
        mode: environment.NODE_ENV,
        buildMode: environment.BUILD_MODE,
        version: environment.REACT_APP_VERSION,
        magentoUrl: environment.REACT_APP_MAGENTO_URL,
    };
};

export const environment = getEnvironment();

export default environment;
