import { CategoryQuery as SourceCategoryQuery } from 'SourceQuery/Category.query';
import { Field } from 'Util/Query';

/** @namespace RokitaBasic/Query/Category/Query */
export class CategoryQuery extends SourceCategoryQuery {
    getCategoryRoot() {
        return new Field('categories').addField(this._getCategoryRootField());
    }

    _getCategoryRootField() {
        return new Field('items').addField(this._getChildrenFields());
    }

    getCategoryDefault() {
        return new Field('getDefaultProductCategory')
            .setAlias('defaultCategory')
            .addFieldList(this.getCategoryDefaultFields());
    }

    getCategoryDefaultFields() {
        return ['name', 'url', 'url_key', 'url_path'];
    }
}

export default new CategoryQuery();
