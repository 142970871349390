import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CUSTOMER_SUB_ACCOUNT } from 'Component/Header/Header.config';
import {
    CUSTOMER_ACCOUNT_OVERLAY_KEY,
    STATE_FORGOT_PASSWORD,
    STATE_LOGGED_IN,
    STATE_SIGN_IN,
} from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import { ACCOUNT_LOGIN_URL, ACCOUNT_URL, ACCOUNT_VERIFICATION_URL } from 'Route/MyAccount/MyAccount.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountOverlayContainer as SourceMyAccountOverlayContainer,
} from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.container';
import { getSessionToken, isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace RokitaBasic/Component/MyAccountOverlay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isVerification: state.ConfigReducer.two_factor_verification_enabled,
});

/** @namespace RokitaBasic/Component/MyAccountOverlay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    checkConsents: (options) =>
        MyAccountDispatcher.then(({ default: dispatcher }) => dispatcher.checkConsents(options, dispatch)),
    acceptedConsents: (options) =>
        MyAccountDispatcher.then(({ default: dispatcher }) => dispatcher.acceptedConsents(options)),
});

export * from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.container';

/** @namespace RokitaBasic/Component/MyAccountOverlay/Container */
export class MyAccountOverlayContainer extends SourceMyAccountOverlayContainer {
    static propTypes = {
        ...SourceMyAccountOverlayContainer.propTypes,
        isVerification: PropTypes.bool.isRequired,
    };

    containerFunctions = {
        ...this.containerFunctions,
        handleConsentsToState: this.handleConsentsToState.bind(this),
        handleConsentsAccept: this.handleConsentsAccept.bind(this),
    };

    __construct(props) {
        this.state = this.redirectOrGetState(props);
    }

    componentDidMount() {
        const { isVerification, history } = this.props;

        const {
            location: { pathname, search },
        } = history;

        if (pathname.includes(ACCOUNT_LOGIN_URL)) {
            if (isVerification && getSessionToken()) {
                history.push({
                    pathname: appendWithStoreCode(ACCOUNT_LOGIN_URL),
                    search,
                });
            }
        } else if (pathname.includes(ACCOUNT_VERIFICATION_URL)) {
            if ((isVerification && !getSessionToken()) || !isVerification) {
                history.push({
                    pathname: appendWithStoreCode(ACCOUNT_LOGIN_URL),
                    search,
                });
            }
        }
    }

    containerProps() {
        const { isVerification } = this.props;
        const { isConsents, isConsentsLoaded, consents } = this.state;

        return {
            ...super.containerProps(),
            isVerification,
            isConsentsLoaded,
            isConsents,
            consents,
        };
    }

    redirectOrGetState(props) {
        const { showOverlay, setHeaderState, isPasswordForgotSend, isMobile } = props;

        const {
            location: { pathname, state: { isForgotPassword } = {} },
        } = history;

        const state = {
            state: isSignedIn() ? STATE_LOGGED_IN : STATE_SIGN_IN,
            isPasswordForgotSend,
            isConsents: false,
            isConsentsLoaded: false,
            consents: null,
        };

        if (pathname !== '/forgot-password' && !isForgotPassword) {
            return state;
        }

        state.state = STATE_FORGOT_PASSWORD;

        setHeaderState({
            name: CUSTOMER_SUB_ACCOUNT,
            title: 'Forgot password',
            onBackClick: (e) => {
                history.push({ pathname: appendWithStoreCode(ACCOUNT_URL) });
                this.handleSignIn(e);
            },
        });

        if (isMobile) {
            history.push({ pathname: appendWithStoreCode(ACCOUNT_URL), state: { isForgotPassword: true } });

            return state;
        }

        showOverlay(CUSTOMER_ACCOUNT_OVERLAY_KEY);

        return state;
    }

    async handleConsentsToState(data) {
        const { checkConsents } = this.props;

        try {
            const consents = await checkConsents(data);

            this.setState({
                consents,
                isConsents: consents?.length > 0,
                isConsentsLoaded: true,
            });

            return {
                consents,
                isConsents: consents?.length > 0,
                isConsentsLoaded: true,
            };
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async handleConsentsAccept(data) {
        const { acceptedConsents } = this.props;

        await acceptedConsents(data);

        this.setState({
            consents: [],
            isConsents: false,
            isConsentsLoaded: true,
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountOverlayContainer);
