import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';

/** @namespace RokitaBasic/Component/MyAccountVerification/Component */
export class MyAccountVerification extends PureComponent {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        onFormError: PropTypes.func.isRequired,
        onGenerateVerificationToken: PropTypes.func.isRequired,
    };

    renderVerificationForm() {
        const { onSubmit, onFormError, onGenerateVerificationToken } = this.props;

        return (
            <Form key="verification" onSubmit={onSubmit} onError={onFormError}>
                <Field
                    label={__('E-mail verification code ...')}
                    type={FIELD_TYPE.email}
                    attr={{
                        id: 'code',
                        name: 'code',
                        autocomplete: 'off',
                    }}
                    validateOn={['onChange']}
                    validationRule={{
                        isRequired: true,
                    }}
                />
                <button
                    type="button"
                    block="Button"
                    mods={{ likeLink: true }}
                    mix={{ block: 'MyAccountOverlay', elem: 'Verification' }}
                    onClick={onGenerateVerificationToken}
                >
                    {__('Send the code again')}
                </button>
                <div block="MyAccountOverlay" elem="SignInButton">
                    <button block="Button">{__('Confirm the code')}</button>
                </div>
            </Form>
        );
    }

    render() {
        return this.renderVerificationForm();
    }
}

export default MyAccountVerification;
