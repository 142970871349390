import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FieldFileContainer as SourceFieldFileContainer } from 'SourceComponent/FieldFile/FieldFile.container';
import { showNotification } from 'Store/Notification/Notification.action';
import { getParsedFile } from 'Util/File';
import { fromJson, joinTextWithSeparator } from 'Util/Text';

import { MAX_FILE_SIZE } from './FieldFile.config';

/** @namespace RokitaBasic/Component/FieldFile/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace RokitaBasic/Component/FieldFile/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message, debug = null) => dispatch(showNotification(type, message, debug)),
});

/** @namespace RokitaBasic/Component/FieldFile/Container */
export class FieldFileContainer extends SourceFieldFileContainer {
    static propTypes = {
        ...SourceFieldFileContainer.propTypes,
        showNotification: PropTypes.func.isRequired,
    };

    containerFunctions = {
        ...this.containerFunctions,
        onRemove: this.onRemove.bind(this),
    };

    state = {
        isLoading: false,
        fileNames: [],
        value: [],
    };

    componentDidMount() {
        const { attr: { defaultValue } = {} } = this.props;

        if (defaultValue) {
            this.fieldRef.fileData = JSON.stringify(defaultValue);

            this.setState({
                fileNames: defaultValue.map(({ filename, filetype }) =>
                    joinTextWithSeparator([filename, filetype], '.')
                ),
                value: defaultValue.map(({ content }) => content),
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { attr: { defaultValue } = {} } = this.props;
        const { attr: { defaultValue: prevDefaultValue } = {} } = prevProps;

        if (JSON.stringify(prevDefaultValue) !== JSON.stringify(defaultValue)) {
            if (this.fieldRef.fileData) {
                this.fieldRef.fileData = JSON.stringify(defaultValue);
            }

            this.setState({
                fileNames: defaultValue.map(({ filename, filetype }) =>
                    joinTextWithSeparator([filename, filetype], '.')
                ),
                value: defaultValue.map(({ content }) => content),
            });
        }
    }

    getAllowedExtensions() {
        const { attr: { accept = '' } = {} } = this.props;

        return (accept || '')
            .split(',')
            .map((type = '') => type.split('/').slice(-1)[0])
            .filter(Boolean);
    }

    getAllowedLabelExtensions() {
        const { attr: { accept = '' } = {} } = this.props;

        return Array.from(
            new Set(
                (accept || '')
                    .split(',')
                    .map((type = '') => joinTextWithSeparator([' ', type.toLowerCase().split('/').slice(-1)[0]], '.'))
            )
        ).join(', ');
    }

    getMaxSize() {
        const { attr: { max } = {} } = this.props;

        if (max) {
            return Math.ceil(Number(max) * 1024 * 1024);
        }

        return MAX_FILE_SIZE;
    }

    getFiles() {
        if (!this.fieldRef || !this.fieldRef?.files) {
            return [];
        }

        return Array.from(this.fieldRef.files);
    }

    getData() {
        if (!this.fieldRef || !this.fieldRef?.fileData) {
            return [];
        }

        return fromJson(this.fieldRef.fileData, []);
    }

    async onRemove(key) {
        const { attr: { disableUpdate = false } = {}, events: { onDelete, onChange } = {} } = this.props;
        const data = this.getData();

        this.setState({ isLoading: true });

        if (this.fieldRef.fileData) {
            this.fieldRef.fileData = JSON.stringify(data.filter((_, fileKey) => key !== fileKey));
        }

        if (typeof onChange === 'function') {
            await onChange(data.filter((_, fileKey) => key !== fileKey));
        }

        if (typeof onDelete === 'function') {
            await onDelete(data.filter((_, fileKey) => key === fileKey));
        }

        if (!disableUpdate) {
            this.setState((prevState) => ({
                fileNames: prevState.fileNames.filter((_, fileKey) => key !== fileKey),
                value: prevState.value.filter((_, fileKey) => key === fileKey),
                isLoading: false,
            }));
        } else {
            this.setState({
                isLoading: false,
            });
        }
    }

    async onChange() {
        const { attr: { disableUpdate = false } = {}, events: { onChange, onAdd } = {}, showNotification } = this.props;

        this.setState({ isLoading: true });

        const allowedExtensions = this.getAllowedExtensions();
        const allowedLabelExtensions = this.getAllowedLabelExtensions();
        const maxSize = this.getMaxSize();
        const files = this.getFiles();
        const data = this.getData();
        const toAddData = [];

        for (const file of files) {
            // eslint-disable-next-line no-await-in-loop
            const { content, filename, filetype, filesize } = (await getParsedFile(file)) || {};

            if (!isEmpty(allowedExtensions) && !allowedExtensions.includes(filetype)) {
                showNotification(
                    'error',
                    __(
                        'File %s has incorrect extension. Acceptable file extensions: %s',
                        joinTextWithSeparator([filename, filetype], '.'),
                        allowedLabelExtensions
                    )
                );
            } else if (filesize > maxSize) {
                showNotification(
                    'error',
                    __(
                        'File %s size exceeded. Maximum size is %s mb',
                        joinTextWithSeparator([filename, filetype], '.'),
                        Math.ceil(maxSize / 1024 / 1024)
                    )
                );
            } else {
                toAddData.push({
                    content,
                    filename,
                    filetype,
                });
            }
        }

        if (this.fieldRef) {
            this.fieldRef.fileData = JSON.stringify([...data, ...toAddData]);
            this.fieldRef.value = '';
        }

        if (typeof onChange === 'function') {
            await onChange([...data, ...toAddData]);
        }

        if (typeof onAdd === 'function') {
            await onAdd(toAddData);
        }

        if (!disableUpdate) {
            this.setState({
                fileNames: [...data, ...toAddData].map(({ filename, filetype }) =>
                    joinTextWithSeparator([filename, filetype], '.')
                ),
                value: [...data, ...toAddData].map(({ content }) => content),
                isLoading: false,
            });
        } else {
            this.setState({
                isLoading: false,
            });
        }
    }

    containerProps() {
        const { events, attr: { autoComplete, autocomplete, ...attr } = {} } = this.props;
        const { fileNames } = this.state;

        return {
            ...super.containerProps(),
            accept: this.getAllowedExtensions(),
            acceptLabel: this.getAllowedLabelExtensions(),
            attr: {
                ...omit(attr, ['defaultValue', 'max', 'accept']),
                autoComplete: autoComplete || autocomplete,
            },
            events: {
                ...omit(events, ['onAdd', 'onRemove']),
                onChange: this.onChange.bind(this),
            },
            fileNames,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldFileContainer);
