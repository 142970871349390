import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { environment } from 'Util/Environment';
import { getErrorMessage } from 'Util/Request';
import { getErrorCategory } from 'Util/Request/Error';
import { GRAPHQL_AUTHENTICATION, GRAPHQL_AUTHORIZATION } from 'Util/Request/Request';

import { AuthenticationError, AuthorizationError, GraphqlError } from './Error';

export { Sentry };

/** @namespace RokitaBasic/Util/Sentry/Index/captureGraphqlException */
export const captureGraphqlException = (errors, options = {}) => {
    Sentry.withScope((scope) => {
        const context = {
            ...(options ?? {}),
        };

        if (typeof errors !== 'string') {
            context.error = JSON.stringify(errors ?? {});
        } else {
            context.error = errors;
        }

        if (environment.version) {
            scope.setTag('frontend_version', environment.version);
        }

        scope.setTransactionName('GraphQL Request');
        scope.setContext('GraphQL', context);

        switch (getErrorCategory(errors)) {
            case GRAPHQL_AUTHORIZATION: {
                Sentry.captureException(new AuthorizationError(getErrorMessage(errors)));
                break;
            }
            case GRAPHQL_AUTHENTICATION: {
                Sentry.captureException(new AuthenticationError(getErrorMessage(errors)));
                break;
            }
            default: {
                Sentry.captureException(new GraphqlError(getErrorMessage(errors)));
                break;
            }
        }
    });
};

Sentry.init({
    dsn: 'https://55478217715942ab9307eeb1cb9f4427@sentry.advox.pl/60',
    enabled: process.env.NODE_ENV === 'production',
    environment: window.location.host,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    debug: process.env.NODE_ENV !== 'production',
});
