import { REQUEST_DELIVERY_DATE_FIELD } from 'Route/CartPage/CartPage.config';

export * from 'SourceUtil/Validator/Validator';
export { default } from 'SourceUtil/Validator/Validator';

/** @namespace RokitaBasic/Util/Validator/getValidationError */
export const getValidationError = (errorFields = []) => {
    return (
        errorFields
            .reduce((errors, error) => {
                const { name } = (error ?? [])[0] ?? {};

                if (name.includes('consent')) {
                    return [
                        ...errors,
                        {
                            name,
                            error: __('Select the required consents'),
                            sort: 2,
                        },
                    ];
                }

                switch (name) {
                    case REQUEST_DELIVERY_DATE_FIELD:
                        return [
                            ...errors,
                            {
                                name,
                                error: __('Select requested delivery date for transport.'),
                                sort: 1,
                            },
                        ];
                    default:
                        return [
                            ...errors,
                            {
                                name,
                                error: __('Incorrect data! Please resolve all field validation errors.'),
                                sort: 0,
                            },
                        ];
                }
            }, [])
            .sort((a, b) => (b.sort > a.sort ? -1 : 1))[0] ?? {}
    );
};
