import TextNumber from 'Component/TextNumber';
import { joinTextWithSeparator } from 'Util/Text';

export * from 'SourceUtil/Price/Price';

/** @namespace RokitaBasic/Util/Price/formatPrice */
export const formatPrice = (value, currency = 'USD', options = {}) => {
    const {
        withoutCurrency = false,
        decimalScale = 2,
        fixedDecimalScale = true,
        suffix = !withoutCurrency && joinTextWithSeparator([' ', currency]),
    } = options ?? {};

    return TextNumber.getValue(value, {
        decimalScale,
        fixedDecimalScale,
        suffix,
    });
};

/** @namespace RokitaBasic/Util/Price/getLowestPriceTiersPrice */
export const getLowestPriceTiersPrice = (price_tiers, currency, format) => {
    const lowestValue = price_tiers.reduce(
        (acc, { final_price: { value } }) => (acc < value ? acc : value),
        price_tiers[0].final_price.value
    );

    if (!format) return lowestValue;

    return formatPrice(lowestValue, currency);
};

/** @namespace RokitaBasic/Util/Price/getHighestPriceTiersPrice */
export const getHighestPriceTiersPrice = (price_tiers, currency, format) => {
    const highestValue = price_tiers.reduce(
        (acc, { final_price: { value } }) => (acc > value ? acc : value),
        price_tiers[0].final_price.value
    );

    if (!format) return highestValue;

    return formatPrice(highestValue, currency);
};
