import parser from 'html-react-parser';
import domToReact from 'html-react-parser/lib/dom-to-react';

import { Html as SourceHtml } from 'SourceComponent/Html/Html.component';

export * from 'SourceComponent/Html/Html.component';

/** @namespace RokitaBasic/Component/Html/Component */
export class Html extends SourceHtml {
    rules = [
        ...this.rules,
        {
            query: { attribs: [{ 'data-content-type': 'html' }] },
            replace: this.replaceHtml,
        },
    ];

    replaceHtml({ children }) {
        return <>{parser(domToReact(children, this.parserOptions), this.parserOptions)}</>;
    }
}

export default Html;
