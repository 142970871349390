import { STORE_CONFIG_KEY } from '@scandipwa/scandipwa/src/component/StoreSwitcher/StoreSwitcher.config';
import i18n from '@scandipwa/webpack-i18n-runtime/src/util/i18n';

import UrlRewritesQuery from 'Query/UrlRewrites.query';
import BrowserDatabase from 'Util/BrowserDatabase';
import { fetchQuery } from 'Util/Request';

import { Url } from '../Url';

export const STORE_CODE_KEY = 'store_code';
export const DEFAULT_POLISH_LOCALE = 'pl';
export const DEFAULT_ENGLISH_LOCALE = 'en';

/** @namespace Rokita/Storefront/Util/Locale */
export class Locale extends Url {
    storeList = [];

    hreflang = [];

    locale = null;

    _priority =
        this.getSelectedLocale() || this.getWebsiteLocale() || this.getNavigatorLocale() || this.getConfigLocale();

    __construct() {
        super.__construct();

        this.setLocale(this._priority);
    }

    isArrangement() {
        return new RegExp('/arrangement/\\d/*').test(window.location.pathname);
    }

    initialize() {
        const storeLocale = this.getStoreLocale(this.locale);

        this.setLocale(this.locale);

        if (i18n.getLocaleList().includes(storeLocale) && i18n.getCurrentLocale() !== storeLocale) {
            i18n.setLocale(storeLocale);
        }
    }

    setLocale(value) {
        if (window.storeList.includes(value)) {
            window.defaultLocale = value;
            this.locale = value;
        }
    }

    getHreflang(value) {
        try {
            return new URL((this.hreflang.find(({ lang }) => lang === value) ?? {}).href).pathname;
        } catch {
            return '';
        }
    }

    setHreflang(value) {
        this.hreflang = value ?? [];
    }

    setStoreList(storeList) {
        window.storeList = (storeList ?? [])
            .reduce((prev, { code }) => [...prev, code || ''], [])
            .sort()
            .reverse();
        window.storeRegexText = `/(${window.storeList.join('|')})?`;

        this.storeList = storeList ?? [];

        this.setLocale(this._priority);
    }

    replaceLocale(value) {
        const hreflang = this.getHreflang(value);

        BrowserDatabase.deleteItem(STORE_CONFIG_KEY);
        BrowserDatabase.setItem(value, STORE_CODE_KEY);

        if (hreflang) {
            if (this.isArrangement()) {
                return this.redirect(
                    window.location.pathname
                        .replace(new RegExp(`/(${window.storeList.join('|')})/`), `/${value}/`)
                        .split('/')
                        .slice(0, -1)
                        .join('/') + hreflang.replace(new RegExp(window.storeRegexText), '')
                );
            }

            return this.redirect(hreflang);
        }

        if (new RegExp(`/(${window.storeList.join('|')})/`).test(window.location.pathname)) {
            return this.redirect(
                window.location.pathname.replace(new RegExp(`/(${window.storeList.join('|')})/`), `/${value}/`)
            );
        }

        return this.redirect(`${value}${window.location.pathname}`);
    }

    getSelectedLocale() {
        return BrowserDatabase.getItem(STORE_CODE_KEY) ?? '';
    }

    getNavigatorLocale() {
        const locale = Locale.getLocale(window.navigator.language);

        if (locale !== DEFAULT_POLISH_LOCALE) {
            if (locale !== DEFAULT_ENGLISH_LOCALE) {
                return DEFAULT_ENGLISH_LOCALE;
            }

            return Locale.getLocale(window.navigator.language);
        }

        return Locale.getLocale(window.navigator.language);
    }

    getWebsiteLocale() {
        return Locale.getLocale(window.location.pathname, window.storeRegexText);
    }

    getConfigLocale() {
        return Locale.getLocale(window.defaultLocale);
    }

    getStoreLocale(locale) {
        return (this.storeList.find(({ code }) => locale === code) || {}).locale;
    }

    async getHrefLang() {
        if (
            new RegExp('(/cart|/account|/my-account|/search)').test(window.location.pathname) ||
            window.location.pathname.replace(new RegExp(window.storeRegexText), '') === '/' ||
            window.location.pathname.replace(new RegExp(window.storeRegexText), '') === ''
        ) {
            return;
        }

        try {
            const { urlResolver } = await fetchQuery(
                UrlRewritesQuery.getQuery({
                    urlParam: this.isArrangement()
                        ? window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
                        : window.location.pathname.replace(new RegExp(window.storeRegexText), ''),
                })
            );
            const { hreflang = [] } = urlResolver ?? {};
            this.setHreflang(hreflang);
        } catch (error) {
            this.setHreflang([]);
        }
    }

    static getLocale(value, regex = window.storeList.join('|')) {
        return new RegExp(regex).exec(value)?.[0]?.replace('/', '') ?? '';
    }
}

export default new Locale();
