import { Field, prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request/Request';

/** @namespace RokitaBasic/Util/Request/Query/fetchQuery */
// eslint-disable-next-line import/prefer-default-export
export const fetchQuery = (rawQueries) => {
    const queries = rawQueries instanceof Field ? [rawQueries] : rawQueries;

    return executeGet(prepareQuery(queries, true));
};
