import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    RouterContainer,
} from 'SourceComponent/Router/Router.container';
import { updateConfigDevice } from 'Store/Config/Config.action';
import { isSignedIn } from 'Util/Auth';
import { isLoginAsUser } from 'Util/Auth/Token';
import { isMobile } from 'Util/Mobile';

export const ConfigDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Config/Config.dispatcher'
);

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export * from 'Component/Router/Router.container';

/** @namespace RokitaBasic/Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateConfigDevice: (device) => dispatch(updateConfigDevice({ ...device, isTablet: isMobile.tablet() })),
    init: () => {
        ConfigDispatcher.then(({ default: dispatcher }) => dispatcher.handleData(dispatch));
        if (!isLoginAsUser()) {
            MyAccountDispatcher.then(({ default: dispatcher }) => dispatcher.handleCustomerDataOnInit(dispatch));
            MyAccountDispatcher.then(({ default: dispatcher }) =>
                dispatcher.initialCart(dispatch, { fetchCart: isSignedIn() })
            );
        }
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
