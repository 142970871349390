import {
    ACCOUNT_CHANGE_PASSWORD_URL,
    ACCOUNT_FORGOT_PASSWORD_URL,
    ACCOUNT_LOGIN_AS_USER_URL,
    ACCOUNT_LOGIN_URL,
    ACCOUNT_VERIFICATION_URL,
} from 'Route/MyAccount/MyAccount.config';
import {
    ACCOUNT_FORGOT_PASSWORD,
    CHANGE_PASSWORD,
    CONFIRM_ACCOUNT,
    COOKIE_POPUP,
    FORGOT_PASSWORD,
    HEADER,
    LOGIN,
    NEW_VERSION_POPUP,
    STYLE_GUIDE,
} from 'SourceComponent/Router/Router.config';

export * from 'SourceComponent/Router/Router.config';

export const WDT_POPUP = 'WDT_POPUP';
export const ADDRESS_POPUP = 'ADDRESS_POPUP';
export const USER_SUPPORT_POPUP = 'USER_SUPPORT_POPUP';
export const CHANGE_CART_TYPE_POPUP = 'CHANGE_CART_TYPE_POPUP';
export const SIDEBAR = 'SIDEBAR';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';

export const VERIFICATION = 'VERIFICATION';
export const LOGIN_AS_USER = 'LOGIN_AS_USER';

export const ARRANGEMENT = 'ARRANGEMENT';
export const ARRANGEMENT_PRODUCT_PAGE = 'ARRANGEMENT_PRODUCT_PAGE';

export const PUBLIC_COMPONENTS = [
    HEADER,
    NEW_VERSION_POPUP,
    COOKIE_POPUP,
    LOGIN,
    ACCOUNT_FORGOT_PASSWORD,
    FORGOT_PASSWORD,
    CHANGE_PASSWORD,
    CONFIRM_ACCOUNT,
    LOGIN_AS_USER,
    STYLE_GUIDE,
    VERIFICATION,
];

export const AUTH_PATHS_ON_SIGNED_IN = [ACCOUNT_CHANGE_PASSWORD_URL, ACCOUNT_LOGIN_AS_USER_URL];

export const AUTH_PATHS = [
    ACCOUNT_FORGOT_PASSWORD_URL,
    ACCOUNT_CHANGE_PASSWORD_URL,
    ACCOUNT_LOGIN_URL,
    ACCOUNT_VERIFICATION_URL,
    ACCOUNT_LOGIN_AS_USER_URL,
];
