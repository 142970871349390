import Decimal from 'decimal.js';
import isString from 'lodash/isString';

export const DECIMAL_PLACES = 3;

/** @namespace RokitaBasic/Util/Number/isDecimal */
export const isDecimal = (value) => value % 1 === 0;

/** @namespace RokitaBasic/Util/Number/toDecimal */
export const toDecimal = (value = 0, decimalPlaces = DECIMAL_PLACES) => {
    return new Decimal(isString(value) ? value?.replace(',', '.')?.replace(/\s+/g, '') : value)
        .toDecimalPlaces(decimalPlaces)
        .toNumber();
};

/** @namespace RokitaBasic/Util/Number/add */
export const add = (a = 0, b = 0) => {
    return toDecimal(Decimal.add(toDecimal(a), toDecimal(b)));
};

/** @namespace RokitaBasic/Util/Number/subtract */
export const subtract = (a = 0, b = 0) => {
    return toDecimal(Decimal.sub(toDecimal(a), toDecimal(b)));
};

/** @namespace RokitaBasic/Util/Number/modulo */
export const modulo = (a = 0, b = 0) => {
    return toDecimal(Decimal.mod(toDecimal(a), toDecimal(b)));
};
