import PropTypes from 'prop-types';

import Field from 'Component/Field';
import FieldGroup from 'Component/FieldGroup';
import Form from 'Component/Form';
import { FieldForm as SourceFieldForm } from 'SourceComponent/FieldForm/FieldForm.component';

import './FieldForm.override.style';

/** @namespace RokitaBasic/Component/FieldForm/Component */
export class FieldForm extends SourceFieldForm {
    static propTypes = {
        fieldMap: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        withForm: PropTypes.bool,
    };

    static defaultProps = {
        fieldMap: [],
        withForm: true,
    };

    get fieldMap() {
        const { fieldMap } = this.props;

        if (Array.isArray(fieldMap) && fieldMap.length > 0) {
            return fieldMap;
        }

        return [
            // // Field
            // {
            //     attr: {
            //         name: __('Email'),
            //         ...
            //     },
            //     events: {
            //         onChange: handleInput,
            //         ...
            //     },
            //     validateOn: [ 'onChange', ... ],
            //     validationRules: {
            //         isRequired: true,
            //         ...
            //     },
            //     ...
            // },
            // // FieldGroup
            // {
            //     attr: { ... }
            //     events: { ... }
            //     fields: [
            //         // Can contain both fields or field groups
            //     ],
            //     ...
            // }
        ];
    }

    renderSection = (section) => {
        const { fields, attr: { name = '' } = {}, name: sectionName } = section;

        // If contains attr fields then outputs data as fields
        if (Array.isArray(fields)) {
            return (
                <FieldGroup {...section} key={name || sectionName}>
                    {fields.map(this.renderSection)}
                </FieldGroup>
            );
        }

        return <Field {...section} key={name} />;
    };

    render() {
        const { withForm } = this.props;

        if (withForm) {
            return (
                <Form {...this.getFormProps()} block="FieldForm">
                    {this.renderFormBody()}
                </Form>
            );
        }

        return this.renderFormBody();
    }
}

export default FieldForm;
