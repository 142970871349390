import React, { PureComponent } from 'react';

/** @namespace RokitaBasic/Component/AttachmentIcon/Component */
export class AttachmentIcon extends PureComponent {
    render() {
        return (
            <svg
                block="AttachmentIcon"
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="14"
                viewBox="0 0 10 14"
                fill="none"
            >
                <path
                    d="M6.16699 0C6.94054 0 7.68241 0.307291 8.22939 0.854272C8.77637 1.40125 9.08366 2.14312 9.08366 2.91667V9.91667C9.08366 10.4529 8.97804 10.9839 8.77284 11.4793C8.56763 11.9747 8.26685 12.4248 7.88768 12.804C7.50851 13.1832 7.05836 13.484 6.56295 13.6892C6.06754 13.8944 5.53656 14 5.00033 14C4.4641 14 3.93311 13.8944 3.4377 13.6892C2.94229 13.484 2.49215 13.1832 2.11297 12.804C1.7338 12.4248 1.43302 11.9747 1.22782 11.4793C1.02261 10.9839 0.916992 10.4529 0.916992 9.91667V5.25H2.08366V9.91667C2.08366 10.6902 2.39095 11.4321 2.93793 11.9791C3.48491 12.526 4.22678 12.8333 5.00033 12.8333C5.77387 12.8333 6.51574 12.526 7.06272 11.9791C7.6097 11.4321 7.91699 10.6902 7.91699 9.91667V2.91667C7.91699 2.68685 7.87173 2.45929 7.78378 2.24697C7.69584 2.03465 7.56693 1.84173 7.40443 1.67923C7.24193 1.51673 7.04901 1.38782 6.83669 1.29988C6.62437 1.21193 6.39681 1.16667 6.16699 1.16667C5.93718 1.16667 5.70962 1.21193 5.4973 1.29988C5.28498 1.38782 5.09206 1.51673 4.92956 1.67923C4.76705 1.84173 4.63815 2.03465 4.5502 2.24697C4.46226 2.45929 4.41699 2.68685 4.41699 2.91667V9.91667C4.41699 10.0714 4.47845 10.2197 4.58785 10.3291C4.69724 10.4385 4.84562 10.5 5.00033 10.5C5.15504 10.5 5.30341 10.4385 5.41281 10.3291C5.5222 10.2197 5.58366 10.0714 5.58366 9.91667V3.5H6.75033V9.91667C6.75033 10.3808 6.56595 10.8259 6.23776 11.1541C5.90957 11.4823 5.46446 11.6667 5.00033 11.6667C4.5362 11.6667 4.09108 11.4823 3.76289 11.1541C3.4347 10.8259 3.25033 10.3808 3.25033 9.91667V2.91667C3.25033 2.14312 3.55762 1.40125 4.1046 0.854272C4.65158 0.307291 5.39345 0 6.16699 0V0Z"
                    fill="currentColor"
                />
            </svg>
        );
    }
}

export default AttachmentIcon;
