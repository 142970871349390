import PropTypes from 'prop-types';

import TextPlaceholder from 'Component/TextPlaceholder';
import { ExpandableContent as SourceExpandableContent } from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.style';

/** @namespace RokitaBasic/Component/ExpandableContent/Component */
export class ExpandableContent extends SourceExpandableContent {
    static propTypes = { ...SourceExpandableContent.propTypes, isOnlyContent: PropTypes.bool };

    static defaultProps = {
        ...SourceExpandableContent.defaultProps,
        isContentOnly: false,
    };

    renderButton() {
        const { isContentExpanded } = this.state;
        const { heading, mix, isContentOnly } = this.props;

        if (isContentOnly) return null;

        return (
            <div
                role="button"
                tabIndex={0}
                block="ExpandableContent"
                elem="Button"
                mods={{ isContentExpanded }}
                mix={{ ...mix, elem: 'ExpandableContentButton' }}
                onClick={this.toggleExpand}
                onKeyDown={this.toggleExpand}
            >
                <div block="ExpandableContent" elem="Heading" mix={{ ...mix, elem: 'ExpandableContentHeading' }}>
                    {typeof heading === 'string' ? <TextPlaceholder content={heading} length="medium" /> : heading}
                </div>
                {this.renderButtonIcon()}
            </div>
        );
    }
}
export default ExpandableContent;
