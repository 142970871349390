export const GET_SALES_DEPARTMENT = 'GET_SALES_DEPARTMENT';
export const CLEAR_SALES_DEPARTMENT = 'CLEAR_SALES_DEPARTMENT';

/** @namespace RokitaBasic/Store/SalesDepartment/Actions/getSalesDepartment */
export const getSalesDepartment = (salesDepartmentAll, salesDepartmentOnlyActive) => ({
    type: GET_SALES_DEPARTMENT,
    salesDepartmentAll,
    salesDepartmentOnlyActive,
});

/** @namespace RokitaBasic/Store/SalesDepartment/Actions/clearSalesDepartment */
export const clearSalesDepartment = (salesDepartmentAll = [], salesDepartmentOnlyActive = []) => ({
    type: CLEAR_SALES_DEPARTMENT,
    salesDepartmentAll,
    salesDepartmentOnlyActive,
});
