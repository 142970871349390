import ConfigQuery from 'Query/Config.query';
import { UPDATE_CONFIG } from 'Store/Config/Config.action';
import { filterStoreConfig } from 'Store/Config/Config.reducer';
import BrowserDatabase from 'Util/BrowserDatabase';

import Url from '../util/Url';

const ConfigQuery__getStoreConfigFields = (args, callback, instance) => [...callback.apply(instance, args), 'locale'];

const ConfigQuery__getStoreListFields = (args, callback, instance) => [...callback.apply(instance, args), 'locale'];

const { locale, storeList } = BrowserDatabase.getItem('config') || {
    locale: window.defaultLocale,
    storeList: [],
};

const ConfigReducer_getInitialState = (args, callback) => ({
    ...callback(...args),
    locale,
    storeList,
});

const ConfigReducer_reducer = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { type, config: { storeConfig, storeList } = {} } = action;

    if (type !== UPDATE_CONFIG) {
        return originalUpdatedState;
    }

    return {
        ...originalUpdatedState,
        ...filterStoreConfig({
            ...storeConfig,
            base_url: Url.replaceOrigin(storeConfig.base_url),
            base_link_url: Url.replaceOrigin(storeConfig.base_link_url),
        }),
        storeList: storeList.map((storeItem) => ({
            ...storeItem,
            base_url: Url.replaceOrigin(storeItem.base_url),
            base_link_url: Url.replaceOrigin(storeItem.base_link_url),
        })),
    };
};

const ConfigDispatcher_prepareRequest = (args, callback) => [...callback(...args), ConfigQuery.getStoreListField()];

const HeaderContainer_mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        storeList: state.ConfigReducer.storeList,
    };
};

const HeaderContainer_containerProps = (args, callback, instance) => {
    const { storeList } = instance.props;

    return {
        ...callback.apply(instance, args),
        storeList,
    };
};

export default {
    'Component/Header/Container': {
        'member-function': {
            containerProps: HeaderContainer_containerProps,
        },
    },
    'Component/Header/Container/mapStateToProps': {
        function: HeaderContainer_mapStateToProps,
    },
    'Store/Config/Reducer/getInitialState': {
        function: ConfigReducer_getInitialState,
    },
    'Store/Config/Reducer/ConfigReducer': {
        function: ConfigReducer_reducer,
    },
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: ConfigDispatcher_prepareRequest,
        },
    },
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields: ConfigQuery__getStoreConfigFields,
            _getStoreListFields: ConfigQuery__getStoreListFields,
        },
    },
};
