import classnames from 'classnames';
import startOfDay from 'date-fns/startOfDay';
import PropTypes from 'prop-types';
import React, { cloneElement, forwardRef, PureComponent } from 'react';
import DatePicker from 'react-datepicker';

import CalendarIcon from 'Component/CalendarIcon';
import { EventsType, FieldAttrType } from 'Type/Field.type';

import { FORMAT_DATE } from './FieldDatePicker.config';

import './FieldDatePicker.style';

export const RenderCustomInput = forwardRef(
    ({ name, onBlur, onChange, onClick, onFocus, onKeyDown, ...props }, ref) => {
        const buttonEvents = {
            onClick,
            onBlur,
            onFocus,
            onKeyDown,
        };

        const inputEvents = {
            onChange,
        };

        return (
            <div block="FieldDatePicker" {...buttonEvents}>
                <CalendarIcon />
                <input
                    {...props}
                    {...inputEvents}
                    id={name}
                    name={name}
                    block="input-control"
                    type="text"
                    ref={ref}
                    readOnly
                />
            </div>
        );
    }
);

/** @namespace RokitaBasic/Component/FieldDatePicker/Component */
export class FieldDatePicker extends PureComponent {
    static propTypes = {
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        onEvent: PropTypes.func.isRequired,
        disabled: PropTypes.bool.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
        selectsRange: PropTypes.bool.isRequired,
        customInput: PropTypes.node,
    };

    static defaultProps = {
        customInput: null,
    };

    getDayClasses(date) {
        const { attr: { includeDates } = {} } = this.props;

        return classnames({
            'react-datepicker__day--include-dates': (includeDates ?? []).some(
                (includeDate) => startOfDay(includeDate).getTime() === startOfDay(date).getTime()
            ),
        });
    }

    render() {
        const {
            attr,
            attr: { placeholder, dateFormat, showTimeSelect } = {},
            label,
            disabled,
            onEvent,
            onClose,
            value,
            selectsRange,
            onReference,
            locale,
            customInput,
        } = this.props;

        const values = selectsRange ? { startDate: value[0], endDate: value[1] } : { selected: value };

        return (
            <DatePicker
                {...attr}
                onChange={onEvent('onChange')}
                onBlur={onEvent('onBlur')}
                onClick={onEvent('onClick')}
                onFocus={onEvent('onFocus')}
                onKeyDown={onEvent('onKeyDown')}
                disabled={disabled}
                customInput={customInput ? cloneElement(customInput) : <RenderCustomInput />}
                ref={onReference}
                dateFormat={dateFormat ?? FORMAT_DATE}
                selectsRange={selectsRange}
                locale={locale}
                autoComplete="off"
                placeholderText={label || placeholder || __('Select date')}
                portalId="root"
                timeCaption={__('Time')}
                fixedHeight
                disabledKeyboardNavigation
                dayClassName={this.getDayClasses.bind(this)}
                calendarClassName={classnames({ 'react-datepicker__with-time-select': !!showTimeSelect })}
                showTimeSelect={showTimeSelect}
                {...values}
            >
                {showTimeSelect ? (
                    <div className="react-datepicker__children" mix={{ block: 'FieldDatePicker', elem: 'Button' }}>
                        <button block="Button" onClick={onClose}>
                            {__('Close')}
                        </button>
                    </div>
                ) : null}
            </DatePicker>
        );
    }
}

export default FieldDatePicker;
