export * from 'SourceUtil/Mobile/isMobile';

export const isMobile = {
    android: (agent = navigator.userAgent) => /android/i.test(agent),
    blackBerry: (agent = navigator.userAgent) => /blackberry/i.test(agent),
    iOS: (agent = navigator.userAgent) => /iphone|ipod|ipad/i.test(agent),
    opera: (agent = navigator.userAgent) => /opera mini/i.test(agent),
    // see https://developer.chrome.com/docs/multidevice/user-agent/ for details
    safari: (agent = navigator.userAgent) =>
        /safari/i.test(agent) && !/chrome/i.test(agent) && !/CriOS/i.test(agent) && !/FxiOS/i.test(agent),
    windows: (agent = navigator.userAgent) => /iemobile/i.test(agent),
    // iPad uses 810 so we need to handle that.
    any: () => window.matchMedia('(max-width: 810px)').matches,
    standaloneMode: () => window.matchMedia('(display-mode: standalone)').matches,
    tablet: () =>
        window.matchMedia('(min-width: 811px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1)').matches,
};

export default isMobile;
