/** @namespace RokitaBasic/Util/Text/stripText */
export const stripText = (data) => String(data).replaceAll(/<\/?[^>]+(>|$)/g, '');

/** @namespace RokitaBasic/Util/Text/overflowText */
export const overflowText = (data, length = 140) => {
    const text = stripText(data);

    if (text.length <= length) {
        return text;
    }

    return `${text.slice(0, length).trim()}...`;
};

/** @namespace RokitaBasic/Util/Text/joinTextWithSeparator */
export const joinTextWithSeparator = (data = [], separator = ' ') =>
    data
        .filter((value) => !!value)
        .map((value) => (typeof value === 'string' ? value.trim() : value))
        .join(separator);

/** @namespace RokitaBasic/Util/Text/checkNA */
export const checkNA = (data, txt) => (txt === false ? data : data || (txt ?? __('N/A')));

/** @namespace RokitaBasic/Util/Text/fromJson */
export const fromJson = (value, defaultValue = []) => {
    try {
        return JSON.parse(value);
    } catch {
        return defaultValue;
    }
};
