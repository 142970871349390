import PropTypes from 'prop-types';

import AttachmentIcon from 'Component/AttachmentIcon';
import CloseIcon from 'Component/CloseIcon';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import FileIcon from 'Component/FileIcon';
import Loader from 'Component/Loader';
import { FieldFile as SourceFieldFile } from 'SourceComponent/FieldFile/FieldFile.component';

/**
 * Field File
 * @class FieldFile
 * @namespace RokitaBasic/Component/FieldFile/Component */
export class FieldFile extends SourceFieldFile {
    static propTypes = {
        ...SourceFieldFile.propTypes,
        fileNames: PropTypes.arrayOf(PropTypes.string.isRequired),
    };

    static defaultProps = {
        ...SourceFieldFile.defaultProps,
        fileNames: [],
    };

    renderInputLabel() {
        const { attr: { id = '', multiple = false } = {} } = this.props;

        const selectLabel = multiple ? __('Add attachments') : __('Add attachment');

        return (
            <label htmlFor={id}>
                <AttachmentIcon />
                <span>{selectLabel}</span>
            </label>
        );
    }

    renderFilesName() {
        const { fileNames = [], onRemove } = this.props;

        if (!fileNames.length) return null;

        return (
            <ul block="FieldFile" elem="List">
                {fileNames.map((fileName, key) => (
                    <li key={fileName}>
                        <FileIcon />
                        {fileName}
                        <button block="FieldFile" elem="Remove" onClick={() => onRemove(key)} type="button">
                            <CloseIcon />
                        </button>
                    </li>
                ))}
            </ul>
        );
    }

    render() {
        const { attr = {}, accept, acceptLabel, events = {}, setRef, isLoading } = this.props;

        return (
            <>
                <Loader isLoading={isLoading} isAttachmentLoader isFullPage />
                <div block="FieldFile" elem="Button">
                    <input {...attr} {...events} ref={(elem) => setRef(elem)} accept={accept} type={FIELD_TYPE.file} />
                    {this.renderInputLabel()}
                </div>
                {acceptLabel ? this.renderSubLabel(acceptLabel) : null}
                {this.renderFilesName()}
            </>
        );
    }
}

export default FieldFile;
