import {
    formatURI,
    getStoreCodePath,
    getWindowId,
    GRAPHQL_URI,
    handleConnectionError,
    HTTP_201_CREATED,
    HTTP_410_GONE,
    HTTP_503_SERVICE_UNAVAILABLE,
} from 'SourceUtil/Request';
import MyAccountDispatcher from 'Store/MyAccount/MyAccount.dispatcher';
import { getAuthorizationToken, getSessionToken, isSignedIn, refreshAuthorizationToken } from 'Util/Auth';
import { refreshUid } from 'Util/Compare';
import environment from 'Util/Environment';
import { getErrorCategory } from 'Util/Request/Error';
import { hash } from 'Util/Request/Hash';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import { captureGraphqlException } from 'Util/Sentry';
import getStore from 'Util/Store';

export const HTTP_401_UNAUTHORIZED = 401;
export const GRAPHQL_AUTHENTICATION = 'graphql-authentication';
export const GRAPHQL_AUTHORIZATION = 'graphql-authorization';
export const GRAPHQL_NO_SUCH_ENTITY = 'graphql-no-such-entity';

export * from 'SourceUtil/Request/Request';

/** @namespace RokitaBasic/Util/Request/wait */
export const wait = (time) => new Promise((resolve) => setTimeout(resolve, time));

/** @namespace RokitaBasic/Util/Request/getGraphqlEndpoint */
export const getGraphqlEndpoint = () =>
    environment.mode === 'development'
        ? getStoreCodePath().concat(GRAPHQL_URI).toString()
        : environment.buildMode === 'magento'
        ? getStoreCodePath().concat(GRAPHQL_URI).toString()
        : new URL(getStoreCodePath().concat(GRAPHQL_URI), environment.magentoUrl).toString();

/** @namespace RokitaBasic/Util/Request/checkForErrors */
export const checkForErrors = (res, options) =>
    new Promise((resolve, reject) => {
        const { errors, data } = res;

        if (errors) {
            if (getErrorCategory(errors) === GRAPHQL_AUTHORIZATION) {
                MyAccountDispatcher.logout(true, false, getStore().dispatch);
            }

            captureGraphqlException(errors, options);
            return reject(errors);
        }

        return resolve(data);
    });

/** @namespace RokitaBasic/Util/Request/appendTokenToHeaders */
export const appendTokenToHeaders = (headers) => {
    const token = getAuthorizationToken();
    const sessionToken = getSessionToken();

    if (sessionToken) {
        return {
            ...headers,
            Authorization: `Bearer ${sessionToken}`,
        };
    }

    if (token) {
        return {
            ...headers,
            Authorization: `Bearer ${token}`,
        };
    }

    return headers;
};

/** @namespace RokitaBasic/Util/Request/parseResponse */
export const parseResponse = async (response, options) => {
    try {
        const data = await response.json();

        return checkForErrors(data);
    } catch (err) {
        captureGraphqlException('Can not parse JSON!', options);
        handleConnectionError(err, 'Can not parse JSON!');

        throw err;
    }
};

/** @namespace RokitaBasic/Util/Request/getFetch */
export const getFetch = (uri, name, signal) =>
    fetch(uri, {
        method: 'GET',
        signal,
        headers: appendTokenToHeaders({
            'Content-Type': 'application/json',
            'Application-Model': `${name}_${getWindowId()}`,
            Accept: 'application/json',
        }),
    });

/** @namespace RokitaBasic/Util/Request/putPersistedQuery */
export const putPersistedQuery = (graphQlURI, query, cacheTTL) =>
    fetch(`${graphQlURI}?hash=${hash(query)}`, {
        method: 'PUT',
        body: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json',
            'SW-Cache-Age': Number.isInteger(cacheTTL) ? cacheTTL : ONE_MONTH_IN_SECONDS,
        },
    });

/** @namespace RokitaBasic/Util/Request/postFetch */
export const postFetch = (graphQlURI, query, variables) =>
    fetch(graphQlURI, {
        method: 'POST',
        body: JSON.stringify({ query, variables }),
        headers: appendTokenToHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }),
    });

/** @namespace RokitaBasic/Util/Request/executeGet */
export const executeGet = async (queryObject, name, cacheTTL, signal) => {
    const { query, variables } = queryObject;
    const uri = formatURI(query, variables, getGraphqlEndpoint());

    try {
        if (isSignedIn()) {
            await refreshAuthorizationToken();
            refreshUid();
        }

        const result = await getFetch(uri, name, signal);

        if (result.status === HTTP_410_GONE) {
            const putResponse = await putPersistedQuery(getGraphqlEndpoint(), query, cacheTTL);

            if (putResponse.status === HTTP_201_CREATED) {
                return parseResponse(await getFetch(uri, name, signal), queryObject);
            }
        }

        if (result.status === HTTP_401_UNAUTHORIZED) {
            MyAccountDispatcher.logout(true, false, getStore().dispatch);
        }

        if (result.status === HTTP_503_SERVICE_UNAVAILABLE) {
            captureGraphqlException(result.statusText, queryObject);
            handleConnectionError(result.status, result.statusText);
            throw new Error(result.statusText);
        }

        return parseResponse(result, queryObject);
    } catch (error) {
        captureGraphqlException('executeGet failed', queryObject);
        handleConnectionError(error, 'executeGet failed');
        throw new Error(error);
    }
};

/** @namespace RokitaBasic/Util/Request/executePost */
export const executePost = async (queryObject) => {
    const { query, variables } = queryObject;

    try {
        if (isSignedIn()) {
            await refreshAuthorizationToken();
            refreshUid();
        }

        const response = await postFetch(getGraphqlEndpoint(), query, variables);
        return parseResponse(response, queryObject);
    } catch (err) {
        captureGraphqlException('executePost failed', queryObject);
        handleConnectionError(err, 'executePost failed');
        throw new Error(err);
    }
};

/** @namespace RokitaBasic/Util/Request/listenForBroadCast */
export const listenForBroadCast = (name) =>
    new Promise((resolve) => {
        const { BroadcastChannel } = window;
        const windowId = getWindowId();

        if (BroadcastChannel) {
            const bc = new BroadcastChannel(`${name}_${windowId}`);
            bc.onmessage = (update) => {
                const {
                    data: { payload: body },
                } = update;

                resolve(checkForErrors(body));
            };
        }
    });
