import { addDays, format as defineFormat, parseISO as defineParseISO } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import enUS from 'date-fns/locale/en-US';
import pl from 'date-fns/locale/pl';

import getStore from 'Util/Store';
import { joinTextWithSeparator } from 'Util/Text';

export const dateLocale = {
    en_GB: enGB,
    en_US: enUS,
    pl_PL: pl,
};

/** @namespace RokitaBasic/Util/Date/parseDateString */
export const parseDateString = (value) => {
    if (typeof value === 'string') {
        if (value.includes('.')) {
            const [dd, mm, yyyy, ...rest] = value.split('.');
            return joinTextWithSeparator([joinTextWithSeparator([yyyy, mm, dd], '/'), ...rest], ' ');
        }

        if (value.includes('-')) {
            return value.replace(/-/g, '/');
        }

        return value;
    }

    return value;
};

/** @namespace RokitaBasic/Util/Date/isISODate */
export const isISODate = (value) => {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(value)) return false;
    return new Date(parseDateString(value)).toISOString() === value;
};

/** @namespace RokitaBasic/Util/Date/isValidDate */
export const isValidDate = (value) => {
    return (
        !Number.isNaN(new Date(parseDateString(value)).getTime()) &&
        !new RegExp('^\\d+$').test(value) && // like: 1234
        !new RegExp('^\\w+(/|#|@|%|!)+\\d+( +)?$').test(value) && // like: a#1 &&
        !/^(\w+)?(\/)(\w+)?$/gi.test(value) && // like: /1 or 1/1 or 1111/11 &&
        !new RegExp('(#|@|%|!)+\\d').test(value) // like: 1#1;
    );
};

/** @namespace RokitaBasic/Util/Date/parseDate */
export const parseDate = (value) => {
    if (!isValidDate(parseDateString(value))) {
        return null;
    }

    if (isISODate(parseDateString(value))) {
        return defineParseISO(parseDateString(value));
    }

    return new Date(parseDateString(value));
};

/** @namespace RokitaBasic/Util/Date/format */
export const format = (value, format = 'dd.MM.yyyy') => {
    if (!value) {
        return null;
    }

    const date = parseDate(value);

    if (!date) {
        return null;
    }

    return defineFormat(date, format, {
        locale: dateLocale[(getStore().getState() ?? {}).ConfigReducer.locale || window.defaultLocale],
    });
};

/** @namespace RokitaBasic/Util/Date/getRangedDates */
export const getRangedDates = (startDate = new Date(), stopDate = new Date()) => {
    const dateArray = [];

    while ((parseDate(startDate) ?? new Date()) <= (parseDate(stopDate) ?? new Date())) {
        dateArray.push(parseDate(startDate));
        startDate = addDays(parseDate(startDate), 1);
    }

    return dateArray;
};
