import TradeAgreementsQuery from 'Query/TradeAgreements.query';
import { ProductListQuery as SourceProductListQuery } from 'SourceQuery/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace RokitaBasic/Query/ProductList/Query */
export class ProductListQuery extends SourceProductListQuery {
    getPriceListMessageQuery() {
        return new Field('getPriceListRangeBlockMessage');
    }

    _getArgumentsMap() {
        return {
            ...super._getArgumentsMap(),
            trade_agreement_item_id: { type: 'Int' },
        };
    }

    _getProductInterfaceFields(isVariant) {
        const {
            isPlp = false,
            isAttachment = false,
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false,
            args: { trade_agreement_item_id } = {},
        } = this.options;

        const fields = [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'salable_qty',
            'url_key',
            'url_suffix',
            this._getStockItemField(),
            this._getPriceRangeField(),
            this._getCustomerGroupAttributesField(),
            ...this._getCustomAttributesFields(),
        ];

        if (!(isPlp && isVariant)) {
            fields.push(
                this._getProductImageField(),
                this._getProductThumbnailField(),
                this._getProductSmallField(),
                this._getShortDescriptionField(),
                'special_from_date',
                'special_to_date',
                this._getTierPricesField()
            );
        }

        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        if (!isVariant) {
            fields.push('url', this._getUrlRewritesFields());
        }

        if (isSingleProduct) {
            fields.push(
                'stock_status',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment()
            );

            if (!isVariant) {
                fields.push(
                    'canonical_url',
                    'meta_title',
                    'meta_keyword',
                    'meta_description',
                    this._getCategoriesField()
                );
            }

            if (isAttachment) {
                fields.push(this._getAttachmentsField());
            }

            if (trade_agreement_item_id) {
                fields.push(
                    new Field('trade_agreement_item').addFieldList(
                        TradeAgreementsQuery._getTradeAgreementsItemFields({
                            withProduct: false,
                            withContractor: false,
                        })
                    )
                );
            }
        }

        return fields;
    }

    _getCartProductInterfaceFields() {
        return [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'url',
            'salable_qty',
            this._getStockItemField(),
            this._getAttributesField(false, true),
            this._getCustomerGroupAttributesField(),
        ];
    }

    _getAttachmentsField() {
        return new Field('attachments').addFieldList(this._getAttachmentsFields());
    }

    _getAttachmentsFields() {
        return ['url', 'name', 'description'];
    }

    _getCustomerGroupAttributesField() {
        return new Field('customer_group_product_attribute').addFieldList(this._getCustomerGroupAttributesFields());
    }

    _getCustomerGroupAttributesFields() {
        return ['unit', 'price_unit', 'unit_of_measure'];
    }

    _getCustomAttributesFields() {
        return ['kind_of_package', 'adr_class', 'sent', 'dualuse', 'un_for_adr', 'quantity_in_package'];
    }
}

export default new ProductListQuery();
