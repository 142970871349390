import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FieldNumberWithControlsContainer as SourceFieldNumberWithControlsContainer } from 'SourceComponent/FieldNumberWithControls/FieldNumberWithControls.container';
import { showNotification } from 'Store/Notification/Notification.action';
import { modulo, toDecimal } from 'Util/Number';
import {
    DEFAULT_MAX_PRODUCTS,
    DEFAULT_MIN_PRODUCTS,
    DEFAULT_MIN_STEP,
    DEFAULT_VALUE_TO_STEP,
    findAndCeilQuantity,
} from 'Util/Product/Extract';

/** @namespace RokitaBasic/Component/FieldNumberWithControls/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace RokitaBasic/Component/FieldNumberWithControls/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
});

/** @namespace RokitaBasic/Component/FieldNumberWithControls/Container */
export class FieldNumberWithControlsContainer extends SourceFieldNumberWithControlsContainer {
    static propTypes = {
        ...SourceFieldNumberWithControlsContainer.propTypes,
        showNotification: PropTypes.func.isRequired,
        checkIncrements: PropTypes.bool,
    };

    static defaultProps = {
        checkIncrements: true,
    };

    containerFunctions = {
        ...this.containerFunctions,
        checkIncrementsValue: this.checkIncrementsValue.bind(this),
    };

    debouncedCheckIncrementsValue = debounce(this.checkIncrementsValue.bind(this), 1000);

    checkIncrementsValue(value, onBlur = false) {
        const {
            attr: { step = DEFAULT_MIN_STEP, max = DEFAULT_MAX_PRODUCTS },
            showNotification,
        } = this.props;

        if (onBlur === true) {
            this.debouncedCheckIncrementsValue.cancel();
        }

        if (typeof value !== 'number') {
            return;
        }

        if (modulo(value, step) === 0 && value <= max) {
            return;
        }

        if (value === DEFAULT_VALUE_TO_STEP) {
            return;
        }

        showNotification('warning', __('The value has been rounded to the next value relative to the step.'));
        this.handleValueChange(findAndCeilQuantity(value, { step, max }) ?? DEFAULT_MIN_PRODUCTS);
    }

    setValue(value) {
        const rangedValue = toDecimal(value || 0);

        this.fieldRef.value = rangedValue;
        this.setState({ value: rangedValue });

        return rangedValue;
    }

    handleValueChange(value) {
        const { checkIncrements, events: { onChange } = {} } = this.props;

        const newValue = this.setValue(value);

        if (checkIncrements) {
            this.debouncedCheckIncrementsValue(newValue);
        }

        if (typeof onChange === 'function') {
            onChange(newValue);
        }
    }

    containerProps() {
        const { events } = this.props;

        return {
            ...super.containerProps(),
            events: omit(events, ['onChange']),
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldNumberWithControlsContainer);
