import Check from '@rokita/permissions';
import { connect } from 'react-redux';

import DocsIcon from 'Component/DocsIcon';
import FormIcon from 'Component/FormIcon';
import { CUSTOMER_ACCOUNT } from 'Component/Header/Header.config';
import HistoryIcon from 'Component/HistoryIcon';
import LikeIcon from 'Component/LikeIcon';
import ReceiptIcon from 'Component/ReceiptIcon';
import UserManageIcon from 'Component/UserManageIcon';
import UsersIcon from 'Component/UsersIcon';
import MyAccount from 'Route/MyAccount/MyAccount.component';
import {
    ACCOUNT_ARRANGEMENTS_URL,
    ACCOUNT_FINANCIAL_DOCUMENT_URL,
    ACCOUNT_FORMS_URL,
    ACCOUNT_LOGIN_URL,
    ACCOUNT_ORDER_HISTORY_URL,
    ACCOUNT_PARTNERS_URL,
    ACCOUNT_PRICE_LIST_URL,
    ACCOUNT_URL,
    ACCOUNT_USER_MANAGE_URL,
    PERMISSIONS,
} from 'Route/MyAccount/MyAccount.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountContainer as SourceMyAccountContainer,
} from 'SourceRoute/MyAccount/MyAccount.container';
import OrderReducer from 'Store/Order/Order.reducer';
import {
    ARRANGEMENTS,
    FINANCIAL_DOCUMENT,
    FORMS,
    ORDER_HISTORY,
    PARTNERS,
    PRICE_LIST,
    USER_MANAGE,
} from 'Type/Account.type';
import { isSignedIn } from 'Util/Auth';
import { scrollToTop } from 'Util/Browser';
import { withReducers } from 'Util/DynamicReducer';
import history from 'Util/History';
import { joinTextWithSeparator } from 'Util/Text';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace RokitaBasic/Route/MyAccount/Container */
export class MyAccountContainer extends SourceMyAccountContainer {
    static tabMap = {
        [PARTNERS]: {
            url: ACCOUNT_PARTNERS_URL,
            tabName: __('Organisational structure'),
            icon: UsersIcon,
            permissions: PERMISSIONS.PARTNERS,
            isFullUrl: true,
        },
        [ORDER_HISTORY]: {
            url: ACCOUNT_ORDER_HISTORY_URL,
            tabName: __('Order history'),
            icon: HistoryIcon,
            permissions: PERMISSIONS.ORDER_HISTORY,
            isFullUrl: true,
        },
        [FINANCIAL_DOCUMENT]: {
            url: ACCOUNT_FINANCIAL_DOCUMENT_URL,
            tabName: __('Settlements'),
            icon: DocsIcon,
            permissions: PERMISSIONS.FINANCIAL_DOCUMENT,
            isFullUrl: true,
        },
        [ARRANGEMENTS]: {
            url: ACCOUNT_ARRANGEMENTS_URL,
            tabName: __('Business agreements'),
            icon: LikeIcon,
            permissions: PERMISSIONS.TRADE_AGREEMENT,
            isFullUrl: true,
        },
        [PRICE_LIST]: {
            url: ACCOUNT_PRICE_LIST_URL,
            tabName: __('Price list'),
            hideName: true,
            icon: ReceiptIcon,
            permissions: PERMISSIONS.PRODUCT_LIST,
            isFullUrl: true,
        },
        [FORMS]: {
            url: ACCOUNT_FORMS_URL,
            tabName: __('Contact'),
            hideName: true,
            icon: FormIcon,
            permissions: PERMISSIONS.SEND_FORM,
            isFullUrl: true,
        },
        [USER_MANAGE]: {
            url: ACCOUNT_USER_MANAGE_URL,
            tabName: __('User manage'),
            icon: UserManageIcon,
            permissions: PERMISSIONS.USER_MANAGE,
            isFullUrl: true,
        },
    };

    static getDefaultUrlByPermissions() {
        return MyAccountContainer.tabMap[MyAccountContainer.getDefaultPageByPermissions()]?.url ?? ACCOUNT_URL;
    }

    static getDefaultPageByPermissions() {
        return ((Object.entries(MyAccountContainer.tabMap) ?? []).find(([_, data]) =>
            Check.permissions(data.permissions ?? [])
        ) ?? [])[0];
    }

    static navigateToSelectedTab(props, state = {}) {
        const { history, isSignedIn, isMobile, selectedTab } = props;
        const { activeTab } = state;

        // redirect to Dashboard, if user visited non-existent or disabled page
        const newActiveTab =
            this.tabMap[selectedTab] && MyAccountContainer.isTabEnabled(props, selectedTab)
                ? selectedTab
                : MyAccountContainer.getDefaultPageByPermissions();
        const { url: activeTabUrl } = MyAccountContainer.tabMap[newActiveTab];

        if (selectedTab !== newActiveTab && isSignedIn && !isMobile) {
            history.push(appendWithStoreCode(joinTextWithSeparator([ACCOUNT_URL, activeTabUrl], '/')));
        }

        if (activeTab !== newActiveTab) {
            return { activeTab: newActiveTab };
        }

        return null;
    }

    __construct() {
        const { updateMeta, toggleOverlayByKey } = this.props;

        this.state = {
            ...MyAccountContainer.navigateToSelectedTab(this.props),
            isEditingActive: false,
            tabName: '',
            stateSubHeading: '',
        };

        if (!isSignedIn()) {
            toggleOverlayByKey(CUSTOMER_ACCOUNT);
        }

        updateMeta({ title: __('My account') });

        this.redirectIfNotSignedIn();
        this.onSignIn();
        this.updateBreadcrumbs();
        scrollToTop();
    }

    static getDerivedStateFromProps(props, state) {
        return MyAccountContainer.navigateToSelectedTab(props, state);
    }

    getTabName() {
        const {
            location: { pathname },
        } = this.props;
        const { tabName: stateTabName, activeTab } = this.state;
        const { tabName, url } = MyAccountContainer.tabMap[activeTab];

        if (!pathname.includes(url)) {
            return stateTabName;
        }

        return tabName;
    }

    tabsFilterEnabled() {
        return Object.entries(MyAccountContainer.tabMap).reduce(
            (enabledTabs, [key, value]) =>
                MyAccountContainer.isTabEnabled(this.props, key) ? { ...enabledTabs, [key]: value } : enabledTabs,
            {}
        );
    }

    onSignOut() {
        const { toggleOverlayByKey } = this.props;
        this.setState({ activeTab: MyAccountContainer.getDefaultPageByPermissions() });
        toggleOverlayByKey(CUSTOMER_ACCOUNT);
        history.replace(appendWithStoreCode(ACCOUNT_LOGIN_URL));
    }

    changeActiveTab(activeTab) {
        const {
            [activeTab]: { url, isFullUrl = false },
        } = this.tabsFilterEnabled(MyAccountContainer.tabMap);

        if (isFullUrl) {
            history.push(appendWithStoreCode(url));
        } else {
            history.push(appendWithStoreCode(joinTextWithSeparator([ACCOUNT_URL, url], '/')));
        }

        this.changeMyAccountHeaderState();
    }

    updateBreadcrumbs() {
        const { updateBreadcrumbs } = this.props;
        const { activeTab } = this.state;
        const { url, tabName, isFullUrl } = MyAccountContainer.tabMap[activeTab] ?? {};
        updateBreadcrumbs([{ url: isFullUrl ? url : joinTextWithSeparator([ACCOUNT_URL, url], '/'), tabName }]);
    }

    render() {
        return (
            <MyAccount
                {...this.containerProps()}
                {...this.containerFunctions}
                tabMap={this.tabsFilterEnabled(MyAccountContainer.tabMap)}
            />
        );
    }
}

export default withReducers({
    OrderReducer,
})(connect(mapStateToProps, mapDispatchToProps)(MyAccountContainer));
