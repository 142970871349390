import {
    VALIDATION_INPUT_TYPE,
    VALIDATION_INPUT_TYPE_NUMBER as SOURCE_VALIDATION_INPUT_TYPE_NUMBER,
    VALIDATION_RULES as SOURCE_VALIDATION_RULES,
} from 'SourceUtil/Validator/Config';

export * from 'SourceUtil/Validator/Config';

export const VALIDATION_INPUT_TYPE_NUMBER = {
    ...SOURCE_VALIDATION_INPUT_TYPE_NUMBER,
    fractional: 'fractional',
};

export const VALIDATION_RULES = {
    ...SOURCE_VALIDATION_RULES,
    [VALIDATION_INPUT_TYPE.fractional]: /^(0|[1-9]\d*)(\.\d+)?$/,
};
