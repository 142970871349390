import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import MyAccountSignIn from 'Component/MyAccountSignIn';
import MyAccountVerification from 'Component/MyAccountVerification';
import { MyAccountOverlay as SourceMyAccountOverlay } from 'SourceComponent/MyAccountOverlay/MyAccountOverlay.component';

import './MyAccountOverlay.override.style';

/** @namespace RokitaBasic/Component/MyAccountOverlay/Component */
export class MyAccountOverlay extends SourceMyAccountOverlay {
    static propTypes = {
        ...SourceMyAccountOverlay.propTypes,
        consents: PropTypes.arrayOf(PropTypes.object),
        isConsents: PropTypes.bool.isRequired,
        isConsentsLoaded: PropTypes.bool.isRequired,
        isVerification: PropTypes.bool.isRequired,
        handleConsentsToState: PropTypes.func.isRequired,
        handleConsentsAccept: PropTypes.func.isRequired,
    };

    static defaultProps = {
        ...SourceMyAccountOverlay.defaultProps,
        consents: [],
    };

    renderSignIn() {
        const {
            state,
            onFormError,
            handleForgotPassword,
            handleCreateAccount,
            isCheckout,
            setLoadingState,
            onSignIn,
            isVerification,
            isConsents,
            isConsentsLoaded,
            consents,
            handleConsentsToState,
            handleConsentsAccept,
        } = this.props;

        return (
            <MyAccountSignIn
                state={state}
                onFormError={onFormError}
                handleForgotPassword={handleForgotPassword}
                handleCreateAccount={handleCreateAccount}
                isCheckout={isCheckout}
                setLoadingState={setLoadingState}
                onSignIn={onSignIn}
                isVerification={isVerification}
                isConsents={isConsents}
                isConsentsLoaded={isConsentsLoaded}
                consents={consents}
                handleConsentsToState={handleConsentsToState}
                handleConsentsAccept={handleConsentsAccept}
            />
        );
    }

    renderVerification() {
        const { onFormError, setLoadingState } = this.props;

        return <MyAccountVerification setLoadingState={setLoadingState} onFormError={onFormError} />;
    }
}

export default withRouter(MyAccountOverlay);
