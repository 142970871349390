import CartQuery from 'Query/Cart.query';
import { Field } from 'Util/Query';

/** @namespace RokitaBasic/Query/Consents/Query */
export class ConsentsQuery {
    getConsents(page, options = {}) {
        return new Field('getConsents')
            .setAlias('consents')
            .addArgument('page', 'PageType!', page)
            .addArgument('checkoutParams', 'Checkout', options)
            .addFieldList(this._getConsentsFields());
    }

    _getConsentsFields() {
        return ['consent_id', 'description', 'is_required', 'is_checkbox'];
    }

    acceptConsentsOnRegister(consentIds) {
        return new Field('acceptConsentsOnRegister').addArgument('consentIds', '[String!]!', consentIds);
    }

    acceptConsentsOnTradeAgreementCheckout(consentIds, tradeAgreementCartId) {
        return new Field('setConsentsOnTradeAgreementCheckout')
            .addArgument('consentIds', '[String!]!', consentIds)
            .addArgument('tradeAgreementCartId', 'Int!', tradeAgreementCartId)
            .addField(CartQuery._getCartSuccessMutation());
    }

    acceptConsentsOnPriceListCheckout(consentIds, guestCartId) {
        return new Field('setConsentsOnCheckout')
            .addArgument('consentIds', '[String!]!', consentIds)
            .addArgument('guestCartId', 'String', guestCartId)
            .addField(CartQuery._getCartSuccessMutation());
    }
}

export default new ConsentsQuery();
