import PropTypes from 'prop-types';

import ErrorIcon from 'Component/ErrorIcon';
import Html from 'Component/Html';
import InfoIcon from 'Component/InfoIcon';
import {
    ANIMATION_DURATION,
    ERROR_NOTIFICATION_LIFETIME,
    ERROR_TYPE,
    NOTIFICATION_LIFETIME,
    notificationType,
} from 'Component/Notification/Notification.config';
import SuccessIcon from 'Component/SuccessIcon';
import WarningIcon from 'Component/WarningIcon';
import { Notification as SourceNotification } from 'SourceComponent/Notification/Notification.component';
import { MixType } from 'Type/Common.type';
import { NotificationType } from 'Type/NotificationList.type';
import CSS from 'Util/CSS';

import './Notification.override.style';

/** @namespace RokitaBasic/Component/Notification/Component */
export class Notification extends SourceNotification {
    static propTypes = {
        isHtml: PropTypes.bool,
        notificationId: PropTypes.string.isRequired,
        notification: NotificationType.isRequired,
        onHideNotification: PropTypes.func.isRequired,
        lifeTime: PropTypes.number,
        id: PropTypes.string,
        mix: MixType,
    };

    static defaultProps = {
        ...SourceNotification.defaultProps,
        isHtml: false,
        notificationId: '',
        mix: {},
    };

    componentDidMount() {
        const {
            notification: { msgType },
            lifeTime,
        } = this.props;

        if (lifeTime !== -1) {
            // Make sure error notification stays a little longer
            if (msgType.toLowerCase() === ERROR_TYPE) {
                this.hideTimeout = setTimeout(() => this.hideNotification(), lifeTime || ERROR_NOTIFICATION_LIFETIME);
            } else {
                this.hideTimeout = setTimeout(() => this.hideNotification(), lifeTime || NOTIFICATION_LIFETIME);
            }

            CSS.setVariable(this.notification, 'animation-duration', `${ANIMATION_DURATION}ms`);
        } else {
            CSS.setVariable(this.notification, 'animation-duration', `0ms`);
        }
    }

    hideNotification = () => {
        const { onHideNotification, notificationId } = this.props;

        if (notificationId) {
            this.setState({ isNotificationVisible: false });

            this.CSSHideTimeout = setTimeout(() => {
                onHideNotification(notificationId);
            }, ANIMATION_DURATION);
        }
    };

    renderIcon() {
        const {
            notification: { msgType },
        } = this.props;

        switch (msgType) {
            case notificationType.success:
                return <SuccessIcon />;
            case notificationType.error:
                return <ErrorIcon />;
            case notificationType.info:
                return <InfoIcon />;
            case notificationType.warning:
                return <WarningIcon />;
            default:
                throw new Error('Notification type unknown');
        }
    }

    renderMessage() {
        const {
            isHtml,
            notification: { msgText },
        } = this.props;

        return (
            <div block="Notification" elem="Wrapper">
                {this.renderIcon()}
                {isHtml ? (
                    <Html content={`<span>${msgText}</span>`} />
                ) : (
                    <p block="Notification" elem="Text">
                        {msgText}
                    </p>
                )}
            </div>
        );
    }

    render() {
        const { notification, id, mix } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgType } = notification;

        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing',
        };

        return (
            <div block="Notification" mods={mods} ref={this.notification} id={id} mix={mix}>
                {this.renderMessage()}
                {this.renderDebug()}
            </div>
        );
    }
}

export default Notification;
