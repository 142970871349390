import {
    GET_FINANCIAL_DOCUMENT_LIST,
    GET_FINANCIAL_DOCUMENT_LOADING,
} from 'Store/FinancialDocument/FinancialDocument.action';

/** @namespace RokitaBasic/Store/FinancialDocument/Reducer/getInitialState */
export const getInitialState = () => ({
    items: [],
    totalPages: 0,
    isLoading: true,
});

/** @namespace RokitaBasic/Store/FinancialDocument/Reducer/FinancialDocumentReducer */
export const FinancialDocumentReducer = (state = getInitialState(), action) => {
    const { type, data: { items, totalPages } = {} } = action;

    switch (type) {
        case GET_FINANCIAL_DOCUMENT_LIST:
            return {
                isLoading: false,
                items,
                totalPages,
            };

        case GET_FINANCIAL_DOCUMENT_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        default:
            return state;
    }
};

export default FinancialDocumentReducer;
