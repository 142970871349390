import { MyAccountQuery as SourceMyAccountQuery } from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

/** @namespace RokitaBasic/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    getSignInMutation(options) {
        const { email, password } = options;

        return new Field('generateCustomerToken')
            .addArgument('email', 'String!', email)
            .addArgument('password', 'String!', password)
            .addFieldList(['token', 'show_two_factor_verification']);
    }

    getDeleteCartMutation() {
        return new Field('deleteCustomerCart');
    }

    getLoginAsUser(hash) {
        return new Field('getLoginAsCustomerToken')
            .setAlias('loginAsUser')
            .addArgument('hash', 'String!', hash)
            .addFieldList(this._getLoginAsUserFields());
    }

    getRefreshCustomerToken() {
        return new Field('refreshCustomerToken').addFieldList(this._getRefreshCustomerTokenFields());
    }

    _getLoginAsUserFields() {
        return ['token'];
    }

    _getRefreshCustomerTokenFields() {
        return ['token'];
    }
}

export default new MyAccountQuery();
