import Check from '@rokita/permissions';
import { connect } from 'react-redux';

import { PERMISSIONS } from 'Route/MyAccount/MyAccount.config';
import { TYPE_CATEGORY, TYPE_CMS_PAGE, TYPE_NOTFOUND, TYPE_PRODUCT } from 'Route/UrlRewrites/UrlRewrites.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    UrlRewritesContainer as SourceUrlRewritesContainer,
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';

export * from 'SourceRoute/UrlRewrites/UrlRewrites.container';

/** @namespace RokitaBasic/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    getType() {
        const {
            urlRewrite: { type, notFound },
        } = this.props;

        if (this.getIsLoading()) {
            return '';
        }

        if (
            notFound ||
            ((type === TYPE_PRODUCT || type === TYPE_CATEGORY) && !Check.permissions(PERMISSIONS.PRODUCT_LIST))
        ) {
            return TYPE_NOTFOUND;
        }

        if (type) {
            return type;
        }

        return '';
    }

    getTypeSpecificProps() {
        const {
            urlRewrite: { id, sku },
        } = this.props;

        if (this.getIsLoading()) {
            return {};
        }

        switch (this.getType()) {
            case TYPE_PRODUCT:
                return { productSKU: sku, id };
            case TYPE_CMS_PAGE:
                return { pageIds: id };
            case TYPE_CATEGORY:
                return { categoryIds: id };
            case TYPE_NOTFOUND:
            default:
                return {};
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);
