import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { NumericFormat, numericFormatter } from 'react-number-format';

import {
    DECIMAL_SCALE,
    DECIMAL_SEPARATOR,
    THOUSAND_GROUP_STYLE,
    THOUSAND_SEPARATOR,
} from 'Component/FieldNumberWithControls/FieldNumberWithControls.config';
import { isDecimal } from 'Util/Number';
import { joinTextWithSeparator } from 'Util/Text';

/** @namespace RokitaBasic/Component/TextNumber/Component */
export class TextNumber extends PureComponent {
    static propTypes = {
        value: PropTypes.number.isRequired,
        suffix: PropTypes.string,
        fixedDecimalScale: PropTypes.bool,
    };

    static defaultProps = {
        suffix: '',
        fixedDecimalScale: false,
    };

    static getValue(value, options = {}) {
        const {
            thousandsGroupStyle = THOUSAND_GROUP_STYLE,
            thousandSeparator = THOUSAND_SEPARATOR,
            decimalSeparator = DECIMAL_SEPARATOR,
            decimalScale = DECIMAL_SCALE,
            fixedDecimalScale = false,
            suffix = '',
        } = options ?? {};

        if (Number.isNaN(value)) {
            value = 0;
        }

        return numericFormatter(String(value), {
            fixedDecimalScale: isDecimal(value) ? fixedDecimalScale : true,
            thousandsGroupStyle,
            thousandSeparator,
            decimalSeparator,
            decimalScale,
            suffix,
        });
    }

    render() {
        const { value, suffix, fixedDecimalScale, ...props } = this.props;

        return (
            <NumericFormat
                {...props}
                suffix={suffix ? joinTextWithSeparator([' ', suffix]) : ''}
                value={value}
                aria-label={__('Value')}
                thousandsGroupStyle={THOUSAND_GROUP_STYLE}
                thousandSeparator={THOUSAND_SEPARATOR}
                decimalSeparator={DECIMAL_SEPARATOR}
                decimalScale={DECIMAL_SCALE}
                fixedDecimalScale={isDecimal(value) ? fixedDecimalScale : true}
                displayType="text"
            />
        );
    }
}

export default TextNumber;
