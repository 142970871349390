import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import Loader from 'Component/Loader';
import {
    AFTER_ITEMS_TYPE,
    AUTH_PATHS,
    AUTH_PATHS_ON_SIGNED_IN,
    BEFORE_ITEMS_TYPE,
    PUBLIC_COMPONENTS,
    SWITCH_ITEMS_TYPE,
} from 'Component/Router/Router.config';
import { HOME_PAGE_URL } from 'Route/HomePage/HomePage.config';
import { ACCOUNT_LOGIN_URL } from 'Route/MyAccount/MyAccount.config';
import { ChildrenType } from 'Type/Common.type';
import { joinTextWithSeparator } from 'Util/Text';
import { appendWithStoreCode } from 'Util/Url';
import { generateParametersToUrl, getParametersFromUrl, setParametersToUrl } from 'Util/Url/Url';

/** @namespace RokitaBasic/Component/Authorization/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isSignedIn: state.MyAccountReducer.isSignedIn,
    isLoading: state.ConfigReducer.isLoading,
});

/** @namespace RokitaBasic/Component/Authorization/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace RokitaBasic/Component/Authorization/Container */
export class AuthorizationContainer extends PureComponent {
    static propsTypes = {
        type: PropTypes.oneOf([BEFORE_ITEMS_TYPE, SWITCH_ITEMS_TYPE, AFTER_ITEMS_TYPE]).isRequired,
        isSignedIn: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        children: ChildrenType.isRequired,
        name: PropTypes.string.isRequired,
    };

    static redirectToPathnameFromUrl(location, history) {
        const [pathname, search] = ((pick(getParametersFromUrl(location), 'from') ?? {}).from ?? '').split('?');

        if (!pathname && !search) {
            return null;
        }

        if (history) {
            history.push({
                pathname,
                search,
            });
        }

        return {
            pathname,
            search,
        };
    }

    redirectToPreviousPathname() {
        const { location } = this.props;

        return (
            <Redirect
                to={{
                    pathname: appendWithStoreCode(ACCOUNT_LOGIN_URL),
                    ...(!location.pathname.includes(AUTH_PATHS) &&
                    location.pathname !== appendWithStoreCode('/') &&
                    location.pathname !== '/'
                        ? {
                              search: generateParametersToUrl({
                                  from: joinTextWithSeparator(
                                      [location.pathname, setParametersToUrl(getParametersFromUrl(location), location)],
                                      '?'
                                  ),
                              }),
                          }
                        : {}),
                }}
            />
        );
    }

    render() {
        const { location: { pathname } = {}, children, name, type, isSignedIn, isLoading } = this.props;

        if (isLoading) {
            return <Loader isLoading isFullPage />;
        }

        if (isSignedIn) {
            if (AUTH_PATHS_ON_SIGNED_IN.some((authPath) => pathname.includes(authPath))) {
                if (PUBLIC_COMPONENTS.includes(name)) {
                    return children;
                }

                return null;
            }

            if (AUTH_PATHS.some((authPath) => pathname.includes(authPath))) {
                if (type === SWITCH_ITEMS_TYPE) {
                    return <Redirect to={appendWithStoreCode(HOME_PAGE_URL)} />;
                }

                return null;
            }

            return children;
        }
        if (!PUBLIC_COMPONENTS.includes(name)) {
            if (type === SWITCH_ITEMS_TYPE) {
                return this.redirectToPreviousPathname();
            }

            return null;
        }

        return children;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthorizationContainer));
