import { updateUrlRewrite } from 'Store/UrlRewrites/UrlRewrites.action';
import { Field } from 'Util/Query';

const UrlRewritesQuery_getUrlResolverFields = (args, callback) => [
    ...callback(...args),
    new Field('hreflangs').setAlias('hreflang').addFieldList(['href', 'lang']),
];

const UrlRewritesStore_getInitialState = (args, callback) => ({
    ...callback(...args),
});

const UrlRewritesContainer_mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;
    return {
        ...callback(...args),
        clearUrlRewrite: () => dispatch(updateUrlRewrite({})),
    };
};

const UrlRewritesContainer_componentWillUnmount = (args, callback, instance) => {
    const { clearUrlRewrite } = instance.props;
    clearUrlRewrite();
    return callback();
};

export default {
    'Store/UrlRewrites/Reducer/getInitialState': {
        function: UrlRewritesStore_getInitialState,
    },
    'Query/UrlRewrites/Query': {
        'member-function': {
            _getUrlResolverFields: UrlRewritesQuery_getUrlResolverFields,
        },
    },
    'Route/UrlRewrites/Container/mapDispatchToProps': {
        function: UrlRewritesContainer_mapDispatchToProps,
    },
    'Route/UrlRewrites/Container': {
        'member-function': {
            componentWillUnmount: UrlRewritesContainer_componentWillUnmount,
        },
    },
};
