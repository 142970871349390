import addSeconds from 'date-fns/addSeconds';
import jwtDecode from 'jwt-decode';

import MyAccountQuery from 'Query/MyAccount.query';
import { ACCOUNT_LOGIN_AS_USER_URL } from 'Route/MyAccount/MyAccount.config';
import { AUTH_TOKEN, MILLISECONDS_IN_SECOND, ONE_HOUR, ONE_HOUR_IN_SECONDS } from 'SourceUtil/Auth/Token';
import { updateCustomerSignInStatus } from 'Store/MyAccount/MyAccount.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteCartId } from 'Util/Cart';
import { removeUid } from 'Util/Compare';
import { prepareMutation } from 'Util/Query';
import { debounce, executePost } from 'Util/Request';
import SessionDatabase from 'Util/SessionDatabase';
import getStore from 'Util/Store';

export const DEFAULT_WEBSITE_CODE = 'base';
export const THIRTY_MINUTES = 30;
export const ONE_MINUTE_IN_SECONDS = 60;
export const TOKEN_REFRESH_DELAY = 500;
export const SESSION_TOKEN = 'session_token';

export * from 'SourceUtil/Auth/Token';

/** @namespace RokitaBasic/Util/Auth/Token/isLoginAsUser */
export const isLoginAsUser = () => {
    return window.location.pathname.includes(ACCOUNT_LOGIN_AS_USER_URL);
};

/** @namespace RokitaBasic/Util/Auth/Token/canRefreshAuthorizationToken */
export const canRefreshAuthorizationToken = () => {
    const { website_code = DEFAULT_WEBSITE_CODE } = window;

    const state = getStore().getState();
    const { webapi_jwtauth_customer_expiration = THIRTY_MINUTES } = state.ConfigReducer;
    const tokens = BrowserDatabase.getItem(AUTH_TOKEN) || {};
    const { exp } = tokens[website_code] || {};

    return (
        addSeconds(Date.now(), Math.floor((Number(webapi_jwtauth_customer_expiration ?? THIRTY_MINUTES) * 60) / 2)) >
        exp
    );
};

/** @namespace RokitaBasic/Util/Auth/Token/getAuthorizationToken */
export const getAuthorizationToken = () => {
    const { website_code = DEFAULT_WEBSITE_CODE } = window;
    const tokens = BrowserDatabase.getItem(AUTH_TOKEN) || {};
    const { token, exp } = tokens[website_code] || {};

    if (token && Date.now() < exp) {
        return token;
    }

    return null;
};

/** @namespace RokitaBasic/Util/Auth/Token/setAuthorizationToken */
export const setAuthorizationToken = (token) => {
    if (token) {
        const { website_code = DEFAULT_WEBSITE_CODE } = window;
        const tokens = BrowserDatabase.getItem(AUTH_TOKEN) || {};
        const { exp } = jwtDecode(token) || {};

        tokens[website_code] = { token, exp: exp * MILLISECONDS_IN_SECOND };
        BrowserDatabase.setItem(tokens, AUTH_TOKEN, exp * MILLISECONDS_IN_SECOND);
    }
};

/** @namespace RokitaBasic/Util/Auth/Token/setSessionToken */
export const setSessionToken = (token) => {
    if (token) {
        const { website_code = DEFAULT_WEBSITE_CODE } = window;
        const tokens = SessionDatabase.getItem(SESSION_TOKEN) || {};

        tokens[website_code] = token;
        SessionDatabase.setItem(tokens, SESSION_TOKEN, ONE_HOUR * ONE_HOUR_IN_SECONDS);
    }
};

/** @namespace RokitaBasic/Util/Auth/Token/deleteSessionToken */
export const deleteSessionToken = () => {
    const { website_code = DEFAULT_WEBSITE_CODE } = window;
    const tokens = SessionDatabase.getItem(SESSION_TOKEN);

    tokens[website_code] = undefined;
    SessionDatabase.setItem(tokens, SESSION_TOKEN);
};

/** @namespace RokitaBasic/Util/Auth/Token/getSessionToken */
export const getSessionToken = () => {
    const { website_code = DEFAULT_WEBSITE_CODE } = window;
    const tokens = SessionDatabase.getItem(SESSION_TOKEN) || {};

    const token = tokens[website_code];

    if (token) {
        return token;
    }

    return null;
};

/** @namespace RokitaBasic/Util/Auth/Token/isSignedIn */
export const isSignedIn = () => {
    const _isSignedIn = !!getAuthorizationToken();
    const store = getStore();
    const { MyAccountReducer: { isSignedIn: isCustomerSignedIn } = {} } = store.getState();
    const { dispatch } = store;

    if (!_isSignedIn && isCustomerSignedIn) {
        deleteCartId();
        dispatch(updateCustomerSignInStatus(false));
        removeUid();

        const MyAccountDispatcher = import('../../store/MyAccount/MyAccount.dispatcher');

        MyAccountDispatcher.then(({ default: dispatcher }) => dispatcher.logout(true, true, dispatch));
    }

    return _isSignedIn;
};

export const refreshAuthorizationToken = debounce(async () => {
    try {
        if (!isSignedIn() || !canRefreshAuthorizationToken()) {
            return Promise.resolve();
        }

        const { refreshCustomerToken: { token } = {} } = await executePost(
            prepareMutation(MyAccountQuery.getRefreshCustomerToken())
        );

        setAuthorizationToken(token);

        return Promise.resolve();
    } catch {
        return Promise.resolve();
    }
}, TOKEN_REFRESH_DELAY);
