import { Field } from 'Util/Query';

/** @namespace RokitaBasic/Query/Verification/Query */
export class VerificationQuery {
    getTFVConfig() {
        return new Field('getTFVConfig').addFieldList(['enabled']);
    }

    validateTFVToken(token) {
        return new Field('validateTFVToken').addArgument('token', 'String!', token);
    }

    saveLastLoggedInDate() {
        return new Field('saveLastLoggedInDate');
    }

    generateTFVToken() {
        return new Field('generateTFVToken');
    }
}

export default new VerificationQuery();
