import { NumericFormat } from 'react-number-format';

import AddIcon from 'Component/AddIcon';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import MinusIcon from 'Component/MinusIcon';
import { FieldNumberWithControls as SourceFieldNumberWithControls } from 'SourceComponent/FieldNumberWithControls/FieldNumberWithControls.component';
import { add, modulo, subtract } from 'Util/Number';
import {
    DEFAULT_MAX_PRODUCTS,
    DEFAULT_MIN_PRODUCTS,
    DEFAULT_MIN_STEP,
    DEFAULT_VALUE_TO_STEP,
} from 'Util/Product/Extract';

import {
    DECIMAL_SCALE,
    DECIMAL_SEPARATOR,
    THOUSAND_GROUP_STYLE,
    THOUSAND_SEPARATOR,
} from './FieldNumberWithControls.config';

/** @namespace RokitaBasic/Component/FieldNumberWithControls/Component */
export class FieldNumberWithControls extends SourceFieldNumberWithControls {
    render() {
        const {
            attr: { min = DEFAULT_MIN_PRODUCTS, max = DEFAULT_MAX_PRODUCTS, step = DEFAULT_MIN_STEP, ...attr },
            events,
            setRef,
            value,
            stateValue,
            checkIncrementsValue,
            handleValueChange,
            isDisabled,
        } = this.props;

        const numberValue = +value || +stateValue || DEFAULT_VALUE_TO_STEP;

        const isAddDisabled =
            value === max || isDisabled || add(numberValue, step) > max || modulo(add(numberValue, step), step) > step;

        const isSubtractDisabled =
            value === min ||
            isDisabled ||
            subtract(numberValue, step) < min ||
            modulo(subtract(numberValue, step), step) > step;

        return (
            <>
                <NumericFormat
                    {...attr}
                    {...events}
                    value={numberValue}
                    disabled={isDisabled}
                    onBlur={() => checkIncrementsValue(numberValue, true)}
                    onValueChange={({ floatValue }) => handleValueChange(floatValue)}
                    getInputRef={(elem) => setRef(elem)}
                    aria-label={__('Value')}
                    thousandsGroupStyle={THOUSAND_GROUP_STYLE}
                    thousandSeparator={THOUSAND_SEPARATOR}
                    decimalSeparator={DECIMAL_SEPARATOR}
                    decimalScale={DECIMAL_SCALE}
                    displayType="input"
                    data-input-number
                />
                <button
                    disabled={isAddDisabled}
                    onClick={() => handleValueChange(numberValue + step)}
                    aria-label={__('Add')}
                    type={FIELD_TYPE.button}
                >
                    <AddIcon block="SubtractButton" isPrimary />
                </button>
                <button
                    disabled={isSubtractDisabled}
                    onClick={() => handleValueChange(numberValue - step)}
                    aria-label={__('Subtract')}
                    type={FIELD_TYPE.button}
                >
                    <MinusIcon block="AddButton" isPrimary />
                </button>
            </>
        );
    }
}

export default FieldNumberWithControls;
