import { getFieldQty, MAX_SALE_QTY, MIN_SALE_QTY } from 'SourceUtil/Product/Extract';
import { add, toDecimal } from 'Util/Number';

export * from 'SourceUtil/Product/Extract';

export const DEFAULT_MIN_STEP = 1;
export const DEFAULT_VALUE_TO_STEP = 0;
export const DEFAULT_MIN_PRODUCTS = 1;
export const DEFAULT_MAX_PRODUCTS = 100_000;
export const DEFAULT_CONFIG_INDEX = -1;

/** @namespace RokitaBasic/Util/Product/Extract/findAndCeilQuantity */
export const findAndCeilQuantity = (toFind, { max = DEFAULT_MAX_PRODUCTS, step = DEFAULT_MIN_STEP } = {}) => {
    if (!toFind) {
        return step;
    }

    let number = step;

    for (let i = 0; i < DEFAULT_MAX_PRODUCTS; i += step) {
        if (add(number, step) > max) {
            break;
        }

        if (number > toFind) {
            break;
        }

        number = add(number, step);
    }

    return toDecimal(number);
};

/** @namespace RokitaBasic/Util/Product/Extract/getQuantity */
export const getQuantity = (product, defaultValue, field, configIndex = DEFAULT_CONFIG_INDEX) => {
    if (!product) {
        return defaultValue;
    }

    const qty = getFieldQty(product, field);

    if (!qty) {
        return defaultValue;
    }

    const { variants } = product;

    if ((!configIndex && !variants) || configIndex === DEFAULT_CONFIG_INDEX) {
        return qty;
    }

    const variantQty = getFieldQty(variants[configIndex] || {}, field);

    return variantQty || qty;
};

/** @namespace RokitaBasic/Util/Product/Extract/getMinQuantity */
export const getMinQuantity = (product, configIndex = DEFAULT_CONFIG_INDEX) =>
    getQuantity(product, DEFAULT_MIN_PRODUCTS, MIN_SALE_QTY, configIndex);

/** @namespace RokitaBasic/Util/Product/Extract/getMaxQuantity */
export const getMaxQuantity = (product, configIndex = DEFAULT_CONFIG_INDEX) =>
    getQuantity(product, DEFAULT_MAX_PRODUCTS, MAX_SALE_QTY, configIndex);
