import EyeIcon from 'Component/EyeIcon';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import FieldDatePicker from 'Component/FieldDatePicker';
import FieldNumberWithControls from 'Component/FieldNumberWithControls';
import FieldSelect from 'Component/FieldSelect';
import { Field as SourceField } from 'SourceComponent/Field/Field.component';
import { noopFn } from 'Util/Common';

import './Field.override.style';

/** @namespace RokitaBasic/Component/Field/Component */
export class Field extends SourceField {
    renderMap = {
        ...this.renderMap,
        [FIELD_TYPE.date]: this.renderDateInput.bind(this),
        [FIELD_TYPE.dateRange]: this.renderDateInput.bind(this),
    };

    renderSelect() {
        const { attr, events, setRef, options, isDisabled = false } = this.props;

        return <FieldSelect attr={attr} events={events} options={options} setRef={setRef} isDisabled={isDisabled} />;
    }

    renderLabel() {
        const { type, label, attr: { name, id } = {} } = this.props;

        if (!label) {
            return null;
        }

        return (
            <div block="Field" elem="LabelContainer">
                <label block="Field" elem="Label" htmlFor={id || name || `input-${type}`}>
                    {label}
                    {this.renderRequiredTag()}
                </label>
            </div>
        );
    }

    renderNumberWithControls() {
        const { attr, events, setRef, value, isDisabled = false, checkIncrements = false } = this.props;

        return (
            <FieldNumberWithControls
                value={value}
                attr={attr}
                events={events}
                setRef={setRef}
                isDisabled={isDisabled}
                checkIncrements={checkIncrements}
            />
        );
    }

    renderDefaultInput() {
        const { type, setRef, attr, events, isDisabled, label, visiblePassword, floatingLabel } = this.props;
        const { placeholder, id, name, ...restAttr } = attr;

        return (
            <input
                id={id || name}
                name={name}
                ref={(elem) => setRef(elem)}
                disabled={isDisabled}
                type={
                    (type === FIELD_TYPE.password && (visiblePassword ? FIELD_TYPE.text : FIELD_TYPE.password)) || type
                }
                placeholder={label && floatingLabel ? ' ' : placeholder}
                {...restAttr}
                {...events}
            />
        );
    }

    renderDateInput() {
        const { type, attr, events, setRef, label, isDisabled = false, customInput } = this.props;

        return (
            <FieldDatePicker
                attr={attr}
                label={label}
                selectsRange={type === FIELD_TYPE.dateRange}
                events={events}
                setRef={setRef}
                isDisabled={isDisabled}
                customInput={customInput}
            />
        );
    }

    renderCheckboxOrRadio() {
        const {
            type,
            setRef,
            attr,
            attr: { id = '', indeterminate = false } = {},
            events: { onChange },
            events,
            isDisabled,
            label,
        } = this.props;

        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn,
        };

        return (
            <label htmlFor={id} block="Field" elem={`${elem}Label`}>
                <input ref={(elem) => setRef(elem)} disabled={isDisabled} type={type} {...attr} {...inputEvents} />
                <div block="input-control" mods={{ indeterminate: type === FIELD_TYPE.checkbox && indeterminate }} />
                {label}
            </label>
        );
    }

    renderPasswordIcon() {
        const { visiblePassword, toggleVisiblePassword } = this.props;

        return (
            <button onClick={toggleVisiblePassword} type="button">
                <EyeIcon visible={visiblePassword} />
            </button>
        );
    }

    render() {
        const {
            attr: { disabled },
            type,
            validationResponse,
            mix,
            isDisabled,
            visiblePassword,
            floatingLabel,
            attr: { readOnly = false } = {},
        } = this.props;
        const inputRenderer = this.renderMap[type] ?? this.renderDefaultInput.bind(this);
        const { mods: { hasError = false } = {} } = mix;

        return (
            <div block="Field" elem="Wrapper" mods={{ type, readOnly }}>
                <div
                    block="Field"
                    mods={{
                        type,
                        isValid: !hasError && validationResponse === true,
                        hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0,
                        visiblePassword,
                        floatingLabel,
                        isDisabled: disabled || isDisabled,
                    }}
                    mix={mix}
                >
                    {!floatingLabel &&
                        (type !== FIELD_TYPE.email ||
                            type !== FIELD_TYPE.text ||
                            type !== FIELD_TYPE.password ||
                            type !== FIELD_TYPE.radio ||
                            type !== FIELD_TYPE.checkbox) &&
                        this.renderLabel()}
                    {inputRenderer && inputRenderer()}
                    {floatingLabel &&
                        (type === FIELD_TYPE.email || type === FIELD_TYPE.text || type === FIELD_TYPE.password) &&
                        this.renderLabel()}
                    {type === FIELD_TYPE.password && this.renderPasswordIcon()}
                </div>
                {this.renderErrorMessages()}
                {this.renderSubLabel()}
            </div>
        );
    }
}

export default Field;
