const MetaContainer_mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        hreflang: state.UrlRewritesReducer.urlRewrite?.hreflang ?? [],
    };
};

const MetaComponent_renderAlternate = (args, callback, instance) => {
    const { hreflang } = instance.props;
    return hreflang.map(({ lang, href }) => <link rel="alternate" hrefLang={lang} href={href} key={lang} />);
};

const MetaComponent_renderMeta = (args, callback, instance) => (
    <>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {callback.apply(instance, args)}
        {instance.renderAlternate()}
    </>
);

const MetaContainer_containerProps = (args, callback, instance) => {
    const { hreflang } = instance.props;
    return {
        ...callback(...args),
        hreflang,
    };
};

export default {
    'Component/Meta/Container/mapStateToProps': {
        function: MetaContainer_mapStateToProps,
    },
    'Component/Meta/Component': {
        'member-function': {
            renderAlternate: MetaComponent_renderAlternate,
            renderMeta: MetaComponent_renderMeta,
        },
    },
    'Component/Meta/Container': {
        'member-function': {
            containerProps: MetaContainer_containerProps,
        },
    },
};
