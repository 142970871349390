import { Helmet } from 'react-helmet';

import { Meta as SourceMeta } from 'SourceComponent/Meta/Meta.component';

/** @namespace RokitaBasic/Component/Meta/Component */
export class Meta extends SourceMeta {
    renderLanguage() {
        return <html lang={window.defaultLocale} />;
    }

    renderMeta() {
        const { metadata } = this.props;

        return (
            <Helmet>
                {this.renderLanguage()}
                {this.renderTitle()}
                {this.renderCanonical()}
                {this.renderAlternate()}
                {metadata.map((tag) => {
                    const { name = null, property = null, content = null } = tag;
                    return <meta key={name || property} name={name} content={content} />;
                })}
            </Helmet>
        );
    }

    render() {
        return this.renderMeta();
    }
}

export default Meta;
