import PropTypes from 'prop-types';

export * from 'SourceType/Account.type';

export const PARTNERS = 'partners';
export const ORDER_HISTORY = 'order-history';
export const FINANCIAL_DOCUMENT = 'financial-document';
export const ARRANGEMENTS = 'arrangements';
export const ARRANGEMENT = 'arrangement';
export const PRICE_LIST = 'price-list';
export const FORMS = 'form';
export const USER_MANAGE = 'user-manage';

export const TabType = PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    icon: PropTypes.func,
});

export const TabMapType = PropTypes.objectOf(TabType);
