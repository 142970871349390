import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

/** @namespace RokitaBasic/Component/EyeIcon/Component */
export class EyeIcon extends PureComponent {
    static propTypes = {
        visible: PropTypes.bool,
    };

    static defaultProps = {
        visible: false,
    };

    render() {
        const { visible } = this.props;

        if (visible) {
            return (
                <svg
                    block="EyeIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="13"
                    viewBox="0 0 17 13"
                    fill="none"
                >
                    <path
                        d="M15.7656 11.5156L13.3516 9.59375C14.0781 8.86719 14.7109 7.95312 15.1562 6.92188C15.2031 6.82812 15.2266 6.64062 15.2266 6.52344C15.2266 6.40625 15.2031 6.24219 15.1562 6.125C13.8906 3.24219 11.3594 1.29688 8.5 1.29688C7.02344 1.29688 5.64062 1.8125 4.49219 2.70312L1.89062 0.640625C1.79688 0.546875 1.67969 0.5 1.5625 0.5C1.375 0.5 1.21094 0.59375 1.11719 0.734375C0.90625 0.96875 0.953125 1.32031 1.21094 1.50781L15.0625 12.3594C15.2969 12.5703 15.6484 12.5234 15.8359 12.2656C16.0703 12.0547 16.0234 11.7031 15.7656 11.5156ZM10.2344 7.15625L8.3125 5.67969C8.40625 5.46875 8.47656 5.25781 8.47656 5C8.47656 4.88281 8.45312 4.76562 8.40625 4.64844C8.45312 4.64844 8.47656 4.625 8.5 4.625C9.53125 4.625 10.375 5.46875 10.375 6.52344C10.375 6.73438 10.3047 6.96875 10.2344 7.15625ZM11.1484 7.88281C11.3594 7.46094 11.5 7.01562 11.5 6.52344C11.5 4.85938 10.1406 3.5 8.5 3.5C7.72656 3.5 7.04688 3.80469 6.53125 4.25L5.40625 3.38281C6.34375 2.75 7.39844 2.375 8.5 2.375C10.8203 2.375 12.9766 3.96875 14.1016 6.5C13.6797 7.4375 13.1172 8.23438 12.4375 8.86719L11.1484 7.88281ZM8.5 9.5C8.80469 9.5 9.10938 9.45312 9.39062 9.35938L8.07812 8.32812C7.53906 8.21094 7.07031 7.83594 6.8125 7.34375L5.5 6.3125C5.5 6.38281 5.5 6.45312 5.5 6.5C5.5 8.16406 6.83594 9.5 8.5 9.5ZM8.5 10.625C6.15625 10.625 4 9.05469 2.875 6.52344C3.13281 5.96094 3.4375 5.44531 3.78906 5L2.89844 4.27344C2.47656 4.83594 2.125 5.44531 1.82031 6.10156C1.77344 6.21875 1.72656 6.38281 1.72656 6.5C1.72656 6.61719 1.75 6.80469 1.79688 6.92188C3.0625 9.80469 5.59375 11.75 8.47656 11.75C9.53125 11.75 10.5391 11.4688 11.4766 10.9766L10.4688 10.2031C9.85938 10.4844 9.17969 10.625 8.5 10.625Z"
                        fill="currentColor"
                    />
                </svg>
            );
        }

        return (
            <svg
                block="EyeIcon"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="11"
                viewBox="0 0 14 11"
                fill="none"
            >
                <path
                    d="M13.4062 5.10156C12.1406 2.21875 9.60938 0.25 6.75 0.25C3.86719 0.25 1.33594 2.21875 0.0703125 5.10156C0.0234375 5.21875 0 5.38281 0 5.5C0 5.61719 0.0234375 5.80469 0.0703125 5.92188C1.33594 8.80469 3.86719 10.75 6.75 10.75C9.60938 10.75 12.1406 8.80469 13.4062 5.92188C13.4531 5.80469 13.5 5.61719 13.5 5.5C13.5 5.38281 13.4531 5.21875 13.4062 5.10156ZM6.75 9.625C4.40625 9.625 2.25 8.05469 1.125 5.52344C2.27344 2.96875 4.40625 1.375 6.75 1.375C9.07031 1.375 11.2266 2.96875 12.3516 5.5C11.2031 8.05469 9.07031 9.625 6.75 9.625ZM6.75 2.5C5.08594 2.5 3.75 3.85938 3.75 5.5C3.75 7.16406 5.08594 8.5 6.75 8.5C8.39062 8.5 9.75 7.16406 9.75 5.52344C9.75 3.85938 8.39062 2.5 6.75 2.5ZM6.75 7.375C5.69531 7.375 4.875 6.55469 4.875 5.5C4.875 5.5 4.875 5.47656 4.875 5.45312C4.99219 5.5 5.10938 5.5 5.25 5.5C6.07031 5.5 6.75 4.84375 6.75 4C6.75 3.88281 6.72656 3.76562 6.67969 3.64844C6.70312 3.64844 6.72656 3.625 6.75 3.625C7.78125 3.625 8.625 4.46875 8.625 5.52344C8.625 6.55469 7.78125 7.375 6.75 7.375Z"
                    fill="currentColor"
                />
            </svg>
        );
    }
}

export default EyeIcon;
