import { UPDATE_TRADE_AGREEMENT_TOTALS } from 'Store/TradeAgreementCart/TradeAgreementCart.action';
import { ONE_HOUR_IN_SECONDS } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { add, toDecimal } from 'Util/Number';

export const TRADE_AGREEMENT_CART_TOTALS = 'trade_agreement_cart_totals';

/** @namespace RokitaBasic/Store/TradeAgreementCart/Reducer/updateTradeAgreementCartTotals */
export const updateTradeAgreementCartTotals = (action) => {
    const {
        tradeAgreementCartData: {
            id: trade_agreement_cart_id,
            trade_agreement = {},
            items = [],
            order_attachments,
        } = {},
    } = action;

    const tradeAgreementCartTotals = {
        ...trade_agreement,
        order_attachments,
        trade_agreement_cart_id,
        items,
        items_qty: items.length ?? 0,
    };

    const tradeAgreementCartQuantities = (items ?? []).reduce((values, { qty, trade_agreement_item_id }) => {
        if (values[trade_agreement_item_id]) {
            return {
                ...values,
                [trade_agreement_item_id]: add(values[trade_agreement_item_id] ?? 0, qty),
            };
        }

        return { ...values, [trade_agreement_item_id]: toDecimal(qty) };
    }, {});

    BrowserDatabase.setItem(tradeAgreementCartTotals, TRADE_AGREEMENT_CART_TOTALS, ONE_HOUR_IN_SECONDS);

    return { tradeAgreementCartTotals, tradeAgreementCartQuantities };
};

/** @namespace RokitaBasic/Store/TradeAgreementCart/Reducer/getInitialState */
export const getInitialState = () => ({
    tradeAgreementCartTotals: BrowserDatabase.getItem(TRADE_AGREEMENT_CART_TOTALS) || {
        items: [],
        items_qty: 0,
    },
    tradeAgreementCartQuantities: {},
});

/** @namespace RokitaBasic/Store/TradeAgreementCart/Reducer/TradeAgreementCartReducer */
export const TradeAgreementCartReducer = (state = getInitialState(), action) => {
    const { type } = action;

    switch (type) {
        case UPDATE_TRADE_AGREEMENT_TOTALS:
            return updateTradeAgreementCartTotals(action);
        default:
            return state;
    }
};

export default TradeAgreementCartReducer;
