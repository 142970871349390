import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Select, { components } from 'react-select';

import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import { EventsType, FieldAttrType, FieldOptionsType, LabelType } from 'Type/Field.type';

import './FieldSelect.style';

/** @namespace RokitaBasic/Component/FieldSelect/Component */
export class FieldSelect extends PureComponent {
    static propTypes = {
        value: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: LabelType,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }).isRequired,
        defaultValue: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: LabelType,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }).isRequired,
        placeholder: PropTypes.string.isRequired,
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        options: FieldOptionsType.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        selectEvents: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        setPortalRef: PropTypes.func.isRequired,
        setFieldRef: PropTypes.func.isRequired,
    };

    renderInput() {
        const {
            attr,
            attr: { id, name },
            isDisabled,
            setFieldRef,
        } = this.props;

        return (
            <input
                {...attr}
                id={id || name}
                name={name}
                disabled={isDisabled}
                type={FIELD_TYPE.text}
                ref={setFieldRef}
            />
        );
    }

    renderOption({ data: { subLabel }, children, ...props }) {
        return (
            <components.Option {...props}>
                <div className="react-select__option--label">{children}</div>
                {subLabel ? <div className="react-select__option--sub-label">{` ${subLabel}`}</div> : null}
            </components.Option>
        );
    }

    renderIndicator(props) {
        return (
            <components.DropdownIndicator {...props}>
                <ChevronIcon direction={TOP} />
                <ChevronIcon direction={BOTTOM} />
            </components.DropdownIndicator>
        );
    }

    render() {
        const {
            value,
            placeholder,
            defaultValue,
            isDisabled,
            options,
            onChange,
            portalRef,
            setPortalRef,
            setSelectRef,
            selectEvents,
        } = this.props;

        return (
            <div block="FieldSelect" setPortalRef={setPortalRef}>
                <Select
                    {...selectEvents}
                    value={value}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    isDisabled={isDisabled}
                    placeholder={placeholder}
                    components={{
                        DropdownIndicator: this.renderIndicator.bind(this),
                        Option: this.renderOption.bind(this),
                    }}
                    menuPosition="fixed"
                    ref={setSelectRef}
                    menuPortalTarget={portalRef}
                    options={options}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    closeMenuOnSelect
                    unstyled
                />
                <div block="FieldSelect" elem="Input">
                    {this.renderInput()}
                </div>
            </div>
        );
    }
}

export default FieldSelect;
