import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React, { createRef, PureComponent } from 'react';

import { EventsType, FieldAttrType, FieldOptionsType } from 'Type/Field.type';
import { DEFAULT_FILTER_VALUE } from 'Util/Filter';

import FieldSelect from './FieldSelect.component';
import { FIELD_SELECT_DEFAULT_VALUE } from './FieldSelect.config';

/** @namespace RokitaBasic/Component/FieldSelect/Container */
export class FieldSelectContainer extends PureComponent {
    static propTypes = {
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        options: FieldOptionsType.isRequired,
        setRef: PropTypes.func.isRequired,
    };

    portalRef = createRef();

    fieldRef = createRef();

    selectRef = createRef();

    state = {
        value: undefined,
    };

    containerFunctions = {
        setFieldRef: this.setFieldRef.bind(this),
        setSelectRef: this.setSelectRef.bind(this),
        setPortalRef: this.setPortalRef.bind(this),
        onChange: this.onChange.bind(this),
    };

    static getDerivedStateFromProps({ attr }, { value }) {
        if (attr?.value && attr?.value !== value) {
            return {
                value: attr?.value,
            };
        }

        return null;
    }

    componentDidMount() {
        if (this.fieldRef.current) {
            this.fieldRef.current.addEventListener('resetField', this.onReset.bind(this));
        }
    }

    componentWillUnmount() {
        if (this.fieldRef.current) {
            this.fieldRef.current.removeEventListener('resetField', this.onReset.bind(this));
        }
    }

    onReset() {
        this.setState({
            value: DEFAULT_FILTER_VALUE,
        });
    }

    onChange({ value }) {
        const {
            events: { onChange },
        } = this.props;

        if (this.fieldRef.current) {
            this.fieldRef.current.value = value;
        }

        if (typeof onChange === 'function') {
            onChange(value);
        }

        this.setState({ value });
    }

    setPortalRef(elem) {
        if (elem) {
            this.portalRef.current = elem;
        }
    }

    setSelectRef(elem) {
        if (elem) {
            this.selectRef.current = elem;
        }
    }

    setFieldRef(elem) {
        const { setRef } = this.props;

        if (elem) {
            setRef(elem);
            this.fieldRef.current = elem;
        }
    }

    getSelectEvents() {
        const { events } = this.props;
        return omit(events, ['onChange']);
    }

    getDefaultState() {
        const { attr: { selectPlaceholder = __('Select item...') } = {} } = this.props;

        return {
            id: FIELD_SELECT_DEFAULT_VALUE,
            value: FIELD_SELECT_DEFAULT_VALUE,
            label: selectPlaceholder,
        };
    }

    getValue() {
        const {
            attr: { noPlaceholder },
        } = this.props;
        const { value } = this.state;

        if (noPlaceholder && !value) {
            return this.getDefaultValue();
        }

        return this.getOptions().find((option) => String(option.value) === String(value)) ?? this.getDefaultValue();
    }

    getDefaultValue() {
        const {
            attr: { defaultValue, noPlaceholder },
        } = this.props;

        if (noPlaceholder && !defaultValue) {
            return this.getOptions()[0] ?? this.getDefaultState();
        }

        return (
            this.getOptions().find((option) => String(option.value) === String(defaultValue)) ?? this.getDefaultState()
        );
    }

    getOptions() {
        const { options, attr: { noPlaceholder } = {} } = this.props;

        if (noPlaceholder) {
            return options;
        }

        return [this.getDefaultState(), ...options];
    }

    isDisabled() {
        const { options, attr: { disabled } = {}, isDisabled } = this.props;

        return disabled || isDisabled || options.length === 0;
    }

    containerProps() {
        const {
            attr,
            attr: { selectPlaceholder: placeholder = __('Select item...') },
        } = this.props;

        return {
            attr,
            placeholder,
            selectEvents: this.getSelectEvents(),
            isDisabled: this.isDisabled(),
            options: this.getOptions(),
            value: this.getValue(),
            defaultValue: this.getDefaultValue(),
            portalRef: this.portalRef.current,
        };
    }

    render() {
        return <FieldSelect {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default FieldSelectContainer;
